import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFilter'
})
export class SearchGlossaryFilterPipe implements PipeTransform{
/**
 * Pipe filters the list of elements based on the search text provided
 *
 * @param items list of elements to search in
 * @param searchText search string
 * @returns list of elements filtered by search text or []
 */
transform(items: any[], searchText: string): any[] {
  if (!items) {
    return [];
  }
  if (!searchText) {
    return items;
  }
  searchText = searchText.toLocaleLowerCase();

  return items.filter(it => {
      if(it[0].toLocaleLowerCase().includes(searchText) || it[1].toLocaleLowerCase().includes(searchText)) {
          return true;
      }
      else {
          return false;
      }
  });
}

}