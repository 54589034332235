import { Injectable } from '@angular/core';
import { Observable  } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { ENDPOINTS } from '@app/endpoints';
import { ApiService } from './api.service';
import { SharedService } from '.';

@Injectable({
    providedIn: 'root'
})
export class QueriesService extends ApiService {
    private subscriberInClaims: any = SharedService.subscriberInClaims;
    public sendMail(formData : FormData): Observable<any> {
        return this.postOperationForFormData(ENDPOINTS.POST_SUPPORT, formData);
    }
}
