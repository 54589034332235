import { Inject, Injectable, InjectionToken } from '@angular/core';

/* eslint-disable @typescript-eslint/naming-convention */
export const AuthWindowRef = new InjectionToken('AuthWindowRef');

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  codeRE = /code=([^&]+)/;
  get cryptoRef() {
    return this.win.crypto || this.win.msCrypto;
  }
  constructor(@Inject(AuthWindowRef) private win) {}

  decodeJWT(jwt) {
    //TODO: detect if encrypted by checking in header
    const [header, encoded] = jwt.split('.');

    try {
      return JSON.parse(atob(encoded));
    } catch (e) {
      return null;
    }
  }

  extractAuthCode(path): string {
    const match = path.match(this.codeRE);
    return match && match[1];
  }

  normalizeKeys(obj: { [name: string]: any }) {
    return Object.keys(obj).reduce((acc, cur) => {
      acc[cur.toUpperCase()] = obj[cur];
      return acc;
    }, {});
  }

  // adapted from source: http://stackoverflow.com/a/11058858
  // this is used in place of TextEncode as the api is not yet
  // well supported: https://caniuse.com/#search=TextEncoder
  textEncode(str: string) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);

    for (let i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return bufView;
  }

  bufferToStr(buffer: Uint8Array) {
    const CHARS =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const ret = [];
    for (let i = 0; i < buffer.byteLength; i++) {
      const index = buffer[i] % CHARS.length;
      ret.push(CHARS[index]);
    }
    return ret.join('');
  }

  generateCodeVerifier(): string {
    const buffer = new Uint8Array(128);
    this.cryptoRef.getRandomValues(buffer);
    return this.bufferToStr(buffer);
  }

  convertToHex(buffer) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    // .replace(/=/g, ''); //TODO: uncomment when idp is fixed.
  }

  deriveChallenge(code: string) {
    // Reference: https://stackoverflow.com/questions/35419421/how-do-i-use-textencoder-in-ie11
    const res = this.cryptoRef.subtle.digest('SHA-256', this.textEncode(code));
    if (res.then) {
      return res.then((buffer) => this.convertToHex(buffer));
    } else {
      // IE11
      return new Promise((resolve, reject) => {
        res.oncomplete = (event) => {
          resolve(this.convertToHex(event.target.result));
        };

        res.onerror = (event) => {
          reject(event);
        };
      });
    }
  }
}
