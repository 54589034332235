import { HttpHeaders } from '@angular/common/http';

export const GLOBAL_BLACKLIST = [
  '00000000',
  '11111111',
  '22222222',
  '33333333',
  '44444444',
  '55555555',
  '66666666',
  '77777777',
  '88888888',
  '99999999',
  '12345678',
  '123123123'
];

export const LOADER_MESSAGE = {
  login_loader_text: 'Logging you in...',
  create_lead_loader_text:
    'We\'re saving your lead. This may take a few seconds.',
  lead_default_loader_text: 'Loading your leads. This may take a few seconds.',
  admin_member_loader_text:
    'Loading your members. This may take a few seconds.',
  submit_otp_loader_text: 'Loading the Dashboard...',
  agent_terminal_loader_text:
    'We\'re processing your request. This may take a few seconds.'
};

export const leadDefaultLeadList = {
  headers: [
    { id: 1, name: 'dbaName', direction: 'none', displayName: 'Company Name' },
    { id: 2, name: 'ownerName', direction: 'none', displayName: 'Owner Name' },
    {
      id: 3,
      name: 'submittedDate',
      direction: 'none',
      displayName: 'Submitted'
    },
    {
      id: 4,
      name: 'salesAgentFirstName',
      direction: 'none',
      displayName: 'Submitted By'
    },
    {
      id: 5,
      name: 'lastUpdatedDate',
      direction: 'desc',
      displayName: 'Lead Last Updated'
    },
    {
      id: 6,
      name: 'assignedSalesAgent',
      direction: 'none',
      displayName: 'Assigned To'
    },
    {
      id: 7,
      name: 'leadStatus',
      direction: 'none',
      displayName: 'Status'
    }
  ]
};

export const STATUSES = {
  statusList: [
    'Lead Submitted',
    'App Created',
    'App In Progress',
    'App Completed',
    'App Submitted',
    'App Rejected',
    'Enrolled Not Processing',
    'Processing',
    'Lead Closed',
    'Lead Contacted',
    'Deferred',
    'No Longer Processing'
  ],
  statusCount: 12
};

export const QUICK_FILTER_TIME_LIST = [
  { days: 7, value: 'Past 7 Days' },
  { days: 30, value: 'Past 30 Days' },
  { days: 60, value: 'Past 60 Days' },
  { days: 90, value: 'Past 90 Days' },
  { days: 100, value: 'Custom...' }
];

export const STATUS_BUBBLE_COLORS = {
  'Lead Submitted': 'gray-status',
  'App Created': 'gray-status',
  'App In Progress': 'yellow-status',
  'App Completed': 'green-status',
  'App Submitted': 'green-status',
  'App Rejected': 'dark-red-status',
  'Enrolled Not Processing': 'enrolled',
  'Processing': 'dark-green-status',
  'Lead Closed': 'red-status',
  'Lead Contacted': 'blue-status',
  'Deferred': 'red-status',
  'No Longer Processing': 'red-status'
};

export const STATUS_CATEGORIES = {
  'Submitted': 0,
  'App In Progress': 0,
  'App Submitted': 0,
  'Enrolled': 0,
  'Processing': 0,
};

export const HTTP_OPTIONS = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

export enum AmexProgramValues {
  DIRECT = 'Direct',
  NONE = 'None',
  OPTBLUE = 'OptBlue'
}

export enum PricingTypes {
  FLAT_RATE = 'Flat Rate',
  INTERCHANGE_PLUS = 'Interchange Plus',
  TIERED = 'Tiered'
}

export enum ActiveMenu {
  DASHBOARD = 'Dashboard',
  LEADS = 'Leads',
  HUB = 'Hub',
  TEAM = 'Team',
  NOTIFICATION_DASHBOARD = 'NotificationDashboard',
  PROFILE = 'Profile',
  OTHER = 'Other'
}

export enum ProductCodes {
  FULLQUAL_DISCOUNTRATE = 'FULLQUAL_DISCOUNTRATE',
  FULLQUAL_PERITEM = 'FULLQUAL_PERITEM',
  MIDQUAL_DISCOUNTRATE = 'MIDQUAL_DISCOUNTRATE',
  MIDQUAL_PERITEM = 'MIDQUAL_PERITEM',
  NONQUAL_DISCOUNTRATE = 'NONQUAL_DISCOUNTRATE',
  NONQUAL_PERITEM = 'NONQUAL_PERITEM',
  DISCOUNTRATE = 'DISCOUNTRATE',
  PERITEM = 'PERITEM'
}

export const DraftSubscribersColumns = [
  { label: "Subscriber Code", class: "first-col" },
  { label: "Name", class: "" },
  { label: "Type", class: "" },
  { label: "Last Updated", class: "" },
];

export const hierarchyLevelDropdownValues = [{
  'PRINCIPAL': 'PB - Principal',
  'SALES ORGANIZATION': 'SO - Sales Organization',
  'SALES CHANNEL': 'SA - Sales Channel',
  'NATIONAL': 'NL - National',
  'REGIONAL': 'RG - Regional',
  'BRANCH': 'BR - Branch',
  'SUPER CHAIN': 'SC - Super Chain',
  'CHAIN': 'CH - Chain',
  'DIVISION': 'DV - Division',
  'STORE': 'ST - Store',
  'MERCHANT': 'MT - Merchant',
  'PARTNER GROUP': 'PG - Partner Group',
  'PARTNER ID': 'PT - Partner ID'}];
