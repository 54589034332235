import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscore'
})
export class ReplaceUnderscorePipe implements PipeTransform {
  text: any;
  /**
   * This Pipe is used to replace underscore(_) with whitespace(" ").
   ** sample input => BUSINESS_OPS, TECH_OPS
   ** sample output => BUSINESS OPS, TECH OPS
   * 
   * Special Scenario: If 'Id' is presnt at the end of text it will convert it to 'ID'.
   * @param value String 
   * @param args Optional
   * @returns String
   * @author Gireesh
   */
  transform(value: any, ...args: any[]): any {
    this.text = value.replace(/_/g, " ").trim();
    return this.text;
  }

}
