import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { Router } from '@angular/router';

import { SharedService } from '../../services';


@Component({
    selector: 'popupmenu',
    templateUrl: './popupmenu.component.html',
    styleUrls: ['./popupmenu.component.scss'],
})
export class PopupMenu implements OnInit {

    @Input()
    private data: any;

    constructor(private _popoverController: PopoverController, private _router: Router, private _sharedService: SharedService) {

    }

    ngOnInit() {
    }

    selectionChanged($event) {
        this._sharedService.setSubscriber(this.data);
        const p = 'subscriber/' + $event;
        this._router.navigate([p]);
        this.onClosePopup();
    }

    onClosePopup() {
        this._popoverController.dismiss([]);
    }

}