import { NgModule } from '@angular/core';

import { NgxPaginationModule } from 'ngx-pagination';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverSubscriberComponent } from '../modules/popover-subscriber/popover-subscriber.component';
import { PopoverAssignedToComponent } from './popover-assignedto/popover-assignedto';
import { NgMultiSelectDropDownModule } from 'src/ng-multiselect-dropdown/src/ng-multiselect-dropdown.module';
import { SharedModule } from './../shared/shared.module';
import { TimeoutWarningComponent } from './timeout-warning/timeout-warning';
import { CustomNotificationComponent } from './custom-notification/custom-notification';
import { FooterComponent } from 'src/app/modules/footer/footer.component';

@NgModule({
    declarations: [
        PopoverAssignedToComponent,
        TimeoutWarningComponent,
        CustomNotificationComponent,
        FooterComponent
    ],
    imports: [
        NgxPaginationModule,
        IonicModule,
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
    ],
    exports: [
        PopoverAssignedToComponent,
        NgxPaginationModule,
        TimeoutWarningComponent,
        CustomNotificationComponent,
        FooterComponent
    ]
})
export class ComponentsModule { }
