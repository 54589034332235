import { ENV } from "@app/env";
import { AuthService } from "@ruf/idp-auth";

export class DynamicFirm {

  public static get firmChange() {
    let localFirm=sessionStorage.getItem("firmName");
    let firm = '';
    if (window.location.href.indexOf('fid') > -1) {
      firm = window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent('fid').replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")

     }
    else {
      firm = ENV.production?'API_PROD':'API_UAT';
     }

    if(localFirm)
    {
      firm=localFirm;
    }


    let tempFirm = firm ? firm.split("-")[0] : '';
    
    if(!localFirm)
    sessionStorage.setItem("firmName",firm)

    if (ENV.authConfig[firm]) {
      if (firm === 'us_worldpay_fis_int') {
        localStorage.setItem("logoutUrl", ENV.authConfig['us_worldpay_fis_int-pbint'].issuer + '/globalLogout.html?client_id=' + ENV.authConfig['us_worldpay_fis_int-pbint'].clientId);
        ENV.IdpGlobalLogout = ENV.authConfig['us_worldpay_fis_int-pbint'].issuer + '/globalLogout.html?client_id=' + ENV.authConfig['us_worldpay_fis_int-pbint'].clientId;
        return ENV.authConfig['us_worldpay_fis_int-pbint'];
      }
     
      ENV.IdpGlobalLogout = ENV.authConfig[firm].issuer + '/globalLogout.html?client_id=' + ENV.authConfig[firm].clientId;
      localStorage.setItem("logoutUrl", ENV.authConfig[firm].issuer + '/globalLogout.html?client_id=' + ENV.authConfig[firm].clientId);
      return ENV.authConfig[firm]
    }
    localStorage.setItem("logoutUrl", ENV.authConfig['us_worldpay_fis_int-pbint'].issuer + '/globalLogout.html?client_id=' + ENV.authConfig['us_worldpay_fis_int-pbint'].clientId);
    ENV.IdpGlobalLogout = ENV.authConfig['us_worldpay_fis_int-pbint'].issuer + '/globalLogout.html?client_id=' + ENV.authConfig['us_worldpay_fis_int-pbint'].clientId;
    return ENV.authConfig['us_worldpay_fis_int-pbint'];
  }

}

export function initApp( authService: AuthService) {
  return () => {
    let localFirm=sessionStorage.getItem("firmName");
    let firm = '';
    if (window.location.href.indexOf('fid') > -1) {
      firm = window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent('fid').replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")

     } else {
      firm = ENV.production?'API_PROD':'API_UAT';
     }

     if(localFirm)
      {
        firm=localFirm;
      }

    if (ENV.authConfig[firm]) {

      let tempFirm = firm.split("-")[0];

      sessionStorage.setItem('fid', JSON.stringify(firm));
      sessionStorage.setItem('sessionFirm', JSON.stringify(tempFirm));
      sessionStorage.setItem('firm', JSON.stringify(firm));

      
      localStorage.setItem("keepAliveUrl", ENV.keepAliveUrl + tempFirm + '/keepSessionAlive.html');
      
    }

  }
}
