import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from "@angular/material/dialog";
import { GlobalConstants } from '../model/GlobalConstants';
import { MessageConstants } from '../model/MessageConstants';

/**
 * @title Dialog with header, scrollable content and actions
 */

 @Component({
  selector: 'dialogAlertContent',
  templateUrl: 'dialogAlertContent.component.html',
  styleUrls: ["./dialogAlertContent.component.scss"]
})

export class DialogAlertContentComponent implements OnInit {

  formData: any = {};
  eventErrorMessage: number;
  successMessage:number;
  errorMessage:string;
  successText:string;
  //contactErrorMessage: number;

  constructor(public dialogref: MatDialogRef<DialogAlertContentComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.formData = data;
  }

  ngOnInit(){    
    this.eventErrorMessage = (this.formData[0].eventErrorMessage != undefined || this.formData[0].eventErrorMessage != null) ? this.formData[0].eventErrorMessage : 0;
    this.successMessage = (this.formData[0].successMessage != undefined || this.formData[0].successMessage != null) ? this.formData[0].successMessage : 0;
    this.errorMessage= (this.formData[0].errorMessage != undefined || this.formData[0].errorMessage != null) ? this.formData[0].errorMessage : '';
    this.successText= (this.formData[0].successText != undefined || this.formData[0].successText != null) ? this.formData[0].successText : '';

    // //this.contactErrorMessage = (this.formData[0].contactErrorMessage != undefined || this.formData[0].contactErrorMessage != null) ? this.formData[0].contactErrorMessage : 1;
  }
 
}


export class dialogErrorAlertContent {}
