import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "estedttime",
})
export class TimeESTEDTPipe implements PipeTransform {

  transform(utcDate: string) {
    var inputDate = moment.utc(utcDate);
    const currentTimeZone = this.getTimeZoneAbbreviation();
    var estDate = inputDate;
    if (currentTimeZone === 'EST') {
        estDate = inputDate.subtract(5, 'hours'); //substract 5 hr
    } else {
        estDate = inputDate.subtract(4, 'hours'); //substract 4 hr
    }
    const formattedDate = estDate.format("YYYY-MM-DD | HH:mm:ss ") + currentTimeZone;
    return formattedDate;
  }

  private getTimeZoneAbbreviation(): string {
    var date = new Date();
    const options = { timeZone: 'America/New_York', timeZoneName: 'short' as const };
    const timeZoneAbbreviation = date.toLocaleTimeString('en-US', options).split(' ')[2];
    return timeZoneAbbreviation;
  }

  convertToUTC(date: string): string {
    const inputDate = new Date(date);
    const currDate = new Date();
    var millisecondDiff = Math.abs(currDate.getTime() - inputDate.getTime());
    if(millisecondDiff < 1000) {
        return moment.utc(date).format("YYYY-MM-DD");
    }
    return moment(date).format("YYYY-MM-DD");
  }
}
