import { Component, OnInit } from '@angular/core';
import { SessionManagementServiceProvider } from 'src/app/services/session-management-service';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'custom-notification',
  templateUrl: 'custom-notification.html',
  styleUrls: ['custom-notification.scss']
})
export class CustomNotificationComponent implements OnInit {

  benRoleError = false;
  benViewError = false;
  
  constructor(private _router: Router,private modalController: ModalController,
    private popoverController: PopoverController, private sessionManagementService: SessionManagementServiceProvider, 
    private navCtrl: NavController, private sharedService: SharedService,private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.hide();
    var modal = document.getElementById("customModal")!;
    modal.style.display = "block";
    this.benViewError=SharedService.benViewError;
    this.benRoleError=SharedService.benRoleError;
  }

  closeModal() {
    var modal = document.getElementById("customModal")!;
    modal.style.display = "none";
}

logOut(): void {
    this.sessionManagementService.logout();
}

}
