export enum EntityTypeEnum {

  PG = 'PG - Partner Group',
  SO = 'SO - Sales Organization',
  SA = 'SA - Sales Channel',
  SC = 'SC - Super Chain',
  NL = 'NL - National',
  CH = 'CH - Chain'

}
