import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {SharedService}  from './shared.service';
import { ENV } from '@app/env';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient, private sharedService: SharedService) { }

    private _getHeaders(): any {
        const obj = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type':'application/json',            
            'Authorization':'Bearer '+SharedService.idTokenKey
        }
        return {
            headers: new HttpHeaders(obj)
        }
    }

    private getOptions(subscribersInClaim: any): any{
        const obj = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type':'application/json',            
            'Authorization':'Bearer '+SharedService.idTokenKey
        }
        let httpOptions;
        if(subscribersInClaim != null || subscribersInClaim != ''){
            httpOptions = {
                headers: new HttpHeaders(obj),
                params: new HttpParams().set("subscribersInClaim", subscribersInClaim)
          }
        }else{
            httpOptions={
                headers: this._getHeaders()
            }
        }
        return httpOptions;
    }

    private _getHeadersForRoutingAPI(): any {
        const obj = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type':'application/json',            
            'Authorization':'Bearer '+SharedService.idTokenKey
        }
        return {
            headers: new HttpHeaders(obj),
            observe: 'response'
        }
    }

    private _getHeadersForSearchAPI(): any {
        const obj = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type':'application/json',            
            'Authorization':'Bearer '+SharedService.idTokenKey
        }
        return {
            headers: new HttpHeaders(obj),
            observe: 'body'
        }
    }

    public get(url: string, params: any = ''): Observable<any> {
        this.sharedService.invokeKeepAlive().subscribe();
        let options = ({
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type':'application/json',
                'Authorization':'Bearer '+SharedService.idTokenKey
            }),
        params: params})
        return this.http.get(
            url, 
            options  
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
    }

   public getSynchronous(url: string, params: any = '') :Promise<any>{
       this.sharedService.invokeKeepAlive().subscribe();

    let options = ({
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type':'application/json',
            'Authorization':'Bearer '+SharedService.idTokenKey
        }),
    params: params})
         return this.http.get(url,
            options).toPromise();
    }
  
    public patch(url: string, params: any, subscribersInClaim: any): Observable<any> {

        this.sharedService.invokeKeepAlive().subscribe();
        return this.http.patch(
            url,
            params,
            this.getOptions(subscribersInClaim)
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
    }

    public patchForRoutingAPI(url: string, params: any): Observable<any> {
        this.sharedService.invokeKeepAlive().subscribe();
        return this.http.patch(
            url,
            params,
            this._getHeadersForRoutingAPI(),
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
    }

    public post(url: string, params: any, subscribersInClaim: any): Observable<any> {
        this.sharedService.invokeKeepAlive().subscribe();
        return this.http.post(
            url,
            params,
           this.getOptions(subscribersInClaim)
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
    }

    public delete(url: string, params: any, subscribersInClaim: any): Observable<any> {
        this.sharedService.invokeKeepAlive().subscribe();
        let options = ({
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type':'application/json',
                'Authorization':'Bearer '+SharedService.idTokenKey
            }),
        params: params})
        return this.http.delete(
            url,
            this.getOptions(subscribersInClaim)
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
    }

    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log errors to console
            return throwError(error as T);
        };
    }
    public postOperationForFormData(url: string, params: any): Observable<any> {
        this.sharedService.invokeKeepAlive().subscribe();
        return this.http.post(
            url,
            params,
            this._getHeadersForFormData()
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
    }
    private _getHeadersForFormData(): any {
        const obj = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Authorization':'Bearer '+SharedService.idTokenKey
        }
        return {
            headers: new HttpHeaders(obj)
        }
    }

    postNewSubscriber(url: string, params: any): Observable<any>{

        this.sharedService.invokeKeepAlive().subscribe();
        return this.http.post(
            url,
            params,
            this._getHeadersForRoutingAPI(),
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
    }
    
    put(url: string, params: any){
        this.sharedService.invokeKeepAlive().subscribe();
            return this.http.put(
                url,
                params,
                this._getHeadersForRoutingAPI(),
            ).pipe(
                tap(() => console.log('error')),
                catchError(this.handleError('error', []))
            );
      }

      postOperationForA4E(url: string, params: any){
        this.sharedService.invokeKeepAlive().subscribe();
        return this.http.post(
            url,
            params,
            this._getHeadersForRoutingAPI(),
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
      }

      postSearch(url: string, params: any){
        this.sharedService.invokeKeepAlive().subscribe();
        return this.http.post(
            url,
            params,
            this._getHeadersForSearchAPI(),
        ).pipe(
            tap(() => console.log('error')),
            catchError(this.handleError('error', []))
        );
      }
}
