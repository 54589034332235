import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { AuthGuard } from './auth-guard.service';
import {
  AUTH_CONFIG,
  resourceServerConfig,
  RufAuthConfig,
} from './auth.service';
import { Http401ErrorInterceptor } from './interceptors/http-401-error-interceptor.service';
import { HttpTokenInterceptor } from './interceptors/token-interceptor.service';
import { TokenStore } from './token-store.service';
import { AuthWindowRef } from './util';

const PROVIDERS = [
  AuthGuard,
  TokenStore,
  {
    provide: AuthWindowRef,
    useValue: window as IWindow,
  },
];

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
export function RufAuthModuleConfigFactory(
  authConfig: RufAuthConfig,
  windowRef
) {
  const conf = resourceServerConfig(
    authConfig ? authConfig.interceptUrls : [],
    windowRef
  );
  return {
    resourceServer: {
      ...conf,
    },
  };
}

@NgModule({
  imports: [CommonModule, OAuthModule.forRoot()],
})
export class AuthModuleNoInterceptors {
  static forRoot(
    config?: RufAuthConfig
  ): ModuleWithProviders<AuthModuleNoInterceptors> {
    return {
      ngModule: AuthModuleNoInterceptors,
      providers: [
        ...PROVIDERS,
        {
          provide: AUTH_CONFIG,
          useValue: config,
        },
        {
          provide: OAuthModuleConfig,
          useFactory: RufAuthModuleConfigFactory,
          deps: [AUTH_CONFIG, AuthWindowRef],
        },
        {
          provide: OAuthStorage,
          useClass: TokenStore,
        },
      ],
    };
  }
}

@NgModule({
  imports: [AuthModuleNoInterceptors],
})
export class AuthModule {
  static forRoot(config?: RufAuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        ...PROVIDERS,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Http401ErrorInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpTokenInterceptor,
          multi: true,
        },
        {
          provide: AUTH_CONFIG,
          useValue: config,
        },
        {
          provide: OAuthModuleConfig,
          useFactory: RufAuthModuleConfigFactory,
          deps: [AUTH_CONFIG, AuthWindowRef],
        },
        {
          provide: OAuthStorage,
          useClass: TokenStore,
        },
      ],
    };
  }
}

interface IWindow extends Window {
  msCrypto?: any;
}
