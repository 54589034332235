import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService, TokenStore } from '@ruf/idp-auth';
import { SessionManagementServiceProvider } from './session-management-service';
import { SharedService } from './shared.service';
import { Events } from './events';
import { ENV } from '@app/env';
import { ENDPOINTS } from '@app/endpoints';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(public sessionManagementService: SessionManagementServiceProvider, public authService: AuthService, public tokenStore: TokenStore, private sharedService: SharedService, private events: Events) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      'Authorization': SharedService.idTokenKey

    });
    const getToken = request.headers.getAll('Authorization');

    if (request.url.indexOf(ENV.GATEWAY_BASE_URL) > -1 || request.url.indexOf(ENV.AWS_BASE_URL) > -1) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + SharedService.idTokenKey)
      });
    }
    return next.handle(request);

  }

}
