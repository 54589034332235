import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogAlertContentComponent } from '../errorAlert/dialogAlertContent.component';
/**
 * @title Dialog with header, scrollable content and actions
 */

@Component({
  selector: "popup-merchant-hierarchy",
  templateUrl: "popup-merchant-hierarchy.component.html",
  styleUrls: ["popup-merchant-hierarchy.component.scss"],
})

export class PopupMerchantHierarchy implements OnInit {
  myForm: FormGroup;
  items: FormArray;
  // seletedEntityType: string ='ISO';
  entityTypeDropdown = [
    { name: 'SO - Sales Organization', value:'SO'},
    { name: 'SA - Sales Channel', value:'SA'},
    { name: 'NL - National', value:'NL'},
    { name: 'SC - Super Chain', value:'SC'},
    { name: 'CH - Chain', value:'CH'},
    { name: 'PG - Partner Group', value:'PG'},
  ];

  viewOnly:boolean = false;
  merchantHierarchy:any = [];

  constructor(
    public fb: FormBuilder,
    private dialogref: MatDialogRef<PopupMerchantHierarchy>,
    private matDailog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}

  ngOnInit(): void {
    const { merchantHierarchy, viewOnly } = this.data;
    this.merchantHierarchy = merchantHierarchy;
    this.viewOnly = viewOnly;  
    this.createForm();

    if(this.merchantHierarchy.length > 0){
      
      this.merchantHierarchy.forEach((element)=>{
        this.onAddRow(element);
      });
    }

    this.myForm.get("items").valueChanges.subscribe( val => {
        
    });
  }

  createForm() {
    const items = this.merchantHierarchy.length > 0 ? [] : [this.initData()];
    this.myForm = this.fb.group({
      items: this.fb.array(items),
    });
    this.items = this.fb.array([]);
  }

  initData(element?:any) {

    return this.fb.group({
      entityType: [element?.entityType || "SO", Validators.required],
      entityValue: [element?.entityValue || "", Validators.required],
    });
  }

  get formArr() {
    return this.myForm.get("items") as FormArray;
  }

  // create another field
  onAddRow(element?:any) {
    (this.myForm.get('items') as FormArray).push(this.initData(element));
    //(this.myForm.get('items') as FormArray).push({ seletedEntityType: 'ISO', options: this.cloneData(this.entityTypeDropdown) }));
  }

  cloneData(entityTypeDropdown) {
    return JSON.parse(JSON.stringify(entityTypeDropdown));
  }

  onRemoveRow(rowIndex:number){
    let items = this.myForm.get('items') as FormArray;
    items.removeAt(rowIndex);
    if(items.length <= 0){
      this.errorMsgDialog("At least one merchant heirarchy value should be added");
    }
  }

  async closeDialog():Promise<void> {
    let items = this.myForm.get("items").value;
    items = await Promise.all( items.filter(function(el) {
      el.entityValue = el.entityValue.replace(/\s*,\s*/g, ','); 
      return el.entityType &&  el.entityValue.trim(); }));
    items = this.viewOnly ? this.merchantHierarchy : items;
    this.dialogref.close(items);
  }

  closePopUp() {
    this.matDailog.closeAll();
  }

  errorMsgDialog(msg){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [{eventErrorMessage: 6,errorMessage: msg}];
    dialogConfig.backdropClass = 'custom-backdrop';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const dialogRef = this.matDailog.open(DialogAlertContentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnDestroy() {}
}
