import { Component, OnInit } from '@angular/core';
import { MessageConstants } from 'src/app/shared/model/MessageConstants';
import { ConfirmationDialog } from 'src/app/shared/popup-confirmation/confirmation-dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SessionManagementServiceProvider } from 'src/app/services/session-management-service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit {

  constructor(private matDailog: MatDialog,private sessionManagementService: SessionManagementServiceProvider) { }

  loginName: any;
  userName: any;
  emailID: any;
  contactNumber: any;
  userAccess: any;
  subscriberAccessList: any;
  benUserDetails: any;
  mobileNumber: any;
  creationDate: any;
  lastLoginDate: any;
  createdBy: any;
  modifiedBy: any;
  modifiedDate: any; 
  fax:any;
  authType: any;
  telephoneExt:any;
  firm:any;
  ngOnInit() {
    this.benUserDetails=JSON.parse(localStorage.getItem("userDetails"));
    this.loginName   = this.benUserDetails.LoginName;
    this.userName = this.benUserDetails.FirstName+" "+this.benUserDetails.LastName;
    this.emailID  = this.benUserDetails.EmailAddress;
    this.contactNumber  = this.benUserDetails.Telephone;
    this.userAccess   = this.benUserDetails.APIDashBoardRole;
    this.mobileNumber = this.benUserDetails.MobileNumber;
    //this.userAccessDescription(this.userAccess);
    this.subscriberAccessList = this.benUserDetails.SubscriberGroups === "WILDCARD" ? "ALL" : this.benUserDetails.SubscriberGroups;
    this.creationDate = this.benUserDetails.CreationDate;
    this.lastLoginDate = this.benUserDetails.LastLoginDate;
    this.createdBy = this.benUserDetails?.CreatedBy?.split('|')[1];
    this.modifiedBy = this.benUserDetails?.ModifiedBy?.split('|')[1];
    this.modifiedDate = this.benUserDetails?.ModifiedDate;
    this.fax = this.benUserDetails?.Fax;
    this.authType = this.benUserDetails?.AuthType;
    this.telephoneExt = this.benUserDetails?.TelePhoneExt;
    this.firm = this.benUserDetails?.Firm;
    this.modifiedDate=this.dateFormator(this.modifiedDate)
    this.creationDate=this.dateFormator(this.creationDate);
    this.lastLoginDate=this.dateFormator(this.lastLoginDate);
    
  }

  // userAccessDescription(userAccess){
  //   switch(userAccess){
  //     case 'BENSystemAdmin':
  //       this.userAccess = "BEN System Administrator";
  //     break;
  //     case 'BENOpsAdmin':
  //       this.userAccess = "BEN Operations Administrator";
  //     break;
  //     case 'BENInternalAdmin':
  //       this.userAccess = "BEN Internal Administrator";
  //     break;
  //     case 'BENReadonly':
  //       this.userAccess = "BEN Read Only User";
  //     break;
  //   }
  // }
  
  changepwd(authType:string){
      const dialogRef = this.matDailog.open(ConfirmationDialog,{
        data:{
          message: MessageConstants.CHANGE_PWD_CONFIRM,
          buttonText: {ok: 'Proceed',cancel: 'Cancel'}
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
         if(authType==='PROD.LOCAL'){
            this.sessionManagementService.logout();
            window.open("https://ssp-prod.fnfis.com/", "_blank");
         }
        }
      });
    }

  dateFormator(ctDate:any){
      const options:any={year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'2-digit',hour12:false,timeZone:'America/New_York',timeZoneName:'short'}
      if(ctDate!=null && ctDate!="")
        {
          var convertDate = new Date(ctDate)
          
          return convertDate.toLocaleString("en-CA",options)
        }
        else
        {
          return ctDate;
        }
    }
}
