import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform{
    transform(data: any[], searchText: string, key:string): any[]{
        if(!searchText) return data;
        const search = searchText.toLowerCase();
        return data.filter(item => {
            return item[key].toLowerCase().includes(search);
        })
    }
}