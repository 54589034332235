import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, switchMap, take, skip } from 'rxjs/operators';
import { Observable, throwError, from } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class Http401ErrorInterceptor implements HttpInterceptor {
  refreshInProgress = false;

  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => from(this.shouldHandle(err, request)).pipe(
          switchMap((handle) => handle ? this.handle401(request, next) : throwError(err))
        ))
    );
  }

  // in this method, we need to pause each request while we are
  // attempting to refresh the token and if it fails, we send the user to login
  private handle401(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshInProgress) {
      // first request to return a 401, attempt refreshing the token
      // if it fails, perform login which will destroy app because of redirect
      this.refreshInProgress = true;
      try {
        this.auth
          .refreshToken()
          .then((_) => {
            this.refreshInProgress = false;
          })
          .catch((_) => {
            this.refreshInProgress = false;
            this.auth.logout(true);
          });
      } catch (err) {
        // We need a try / catch block here becuase angular-oauth2-oidc refreshToken() throws an error that's not a Promise.
        this.auth.logout(true);
      }
    }

    // retry the request once we receive an idToken
    // skip is used to skip previously 'replayed' token so that when 'switchMap' is called,
    // it gets the newly refreshed token
    return this.auth.idToken$.pipe(
      skip(1),
      take(1),
      switchMap((_) =>
        next.handle(this.auth.createAuthenticatedRequest(request))
      )
    );
  }

  private async shouldHandle(
    err: any,
    request: HttpRequest<any>
  ): Promise<boolean> {
    return (
      err instanceof HttpErrorResponse &&
      err.status === 401 &&
      (await this.auth.shouldIntercept(request))
    );
  }
}
