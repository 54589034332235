import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'removeUnderscores'
})

export class RemoveUnderscores implements PipeTransform {
    transform(value: any, ...args: any[]) {
        if(value) {
            let newValue = value.replace(/_/g, ' ');
            return `${newValue}`;
        }
    }
}