import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MerchantHierarchy } from './../model/merchantHierarchy';
import { EventService } from "src/app/services/event.service";
import { EntityTypeEnum } from '../model/EntityTypeEnum';
import { MerchantHierarchyRequest } from '../model/merchantHierarchyRequest';
import { MerchantEntity } from '../model/merchantHierarchyRequest';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageConstants } from 'src/app/shared/model/MessageConstants';
import { AlertController } from '@ionic/angular';
import { DialogAlertContentComponent} from 'src/app/shared/errorAlert/dialogAlertContent.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ErrorService } from 'src/app/services/error.service';
import { CustomValidator } from 'src/app/validators/custom.validators';
import { ConfirmationDialog } from "../popup-confirmation/confirmation-dialog";

@Component({
  selector: "popup-view-merchant-hierarchy",
  templateUrl: "popup-view-merchant-hierarchy.component.html",
  styleUrls: ["popup-view-merchant-hierarchy.component.scss"],
})

export class PopupViewMerchantHierarchy implements OnInit {

  @Input() addAndEditMode = "add";
  @Input() hierarchyDataList: MerchantHierarchy[] = [];
  merchantRequest: MerchantHierarchyRequest = {} as any
  enableButtons: boolean = false;
  entityTypes = Object.values(EntityTypeEnum);
  inputHierarchyForm: FormGroup;
  hierarchyForm: FormGroup;
  items: FormArray;
  showRows = false;
  benUserDetails: any;
  dashBoardRole: any;
  userID: any;
  loading: boolean = true;
  selectedOptions: EntityTypeEnum[] = [];
  entityTypeDropdown = [
    { name: 'SO - Sales Organization', value:'SO'},
    { name: 'SA - Sales Channel', value:'SA'},
    { name: 'NL - National', value:'NL'},
    { name: 'PG - Partner Group', value:'PG'},
    { name: 'SC - Super Chain', value:'SC'},
    { name: 'CH - Chain', value:'CH'},
  ];

  loadingMessages = [
    "No records found",
    "Please wait, loading...",
    "Http status error",
    "Session timed out",
  ];

  constructor(
    private eventService: EventService,
    private spinner: NgxSpinnerService,
    private _alertController: AlertController,
    private matDailog: MatDialog,
    public fb: FormBuilder,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
   
    this.benUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.userID = this.benUserDetails.LoginName;
    this.dashBoardRole = this.benUserDetails.APIDashBoardRole;
    this.getMerchantHierarchy(this.data);
    this.createForm();
    this.inputHierarchyForm.get("items").valueChanges.subscribe( val => { });
    if(this.hierarchyDataList.length > 0) {

      this.hierarchyDataList.forEach((element)=>{
        this.onAddRow(element);
      });

    }

  }

  createForm() {
    this.inputHierarchyForm = this.fb.group({
      items: this.fb.array([this.initData()]),
    });
    this.items = this.fb.array([]);
  }

  initData(element?:any) {
    return this.fb.group({
      entityType: [element?.entityType || "SO", Validators.required],
      entityValue: [element?.entityValue || "", Validators.compose([Validators.required, CustomValidator.specialCharValidator])],
    });
  }

  get formArr() {
    return this.inputHierarchyForm.get("items") as FormArray;
  }

  // create another field
  onAddRow(element?:any) {
    (this.inputHierarchyForm.get('items') as FormArray).push(this.initData(element));
  }

  cloneData(entityTypeDropdown) {
    return JSON.parse(JSON.stringify(entityTypeDropdown));
  }

  onRemoveRow(rowIndex:number){
    let items = this.inputHierarchyForm.get('items') as FormArray;
    items.removeAt(rowIndex);

  }

  async closeDialog():Promise<void> {
    if(this.inputHierarchyForm.invalid){
      this.errorMsgDialog("Please enter valid entity values");
    }else{
    let items: MerchantEntity[] = this.inputHierarchyForm.get("items").value;
    items = await Promise.all( items.filter(function(el) {
      el.entityValue = el.entityValue.replace(/\s*,\s*/g, ',');
      return el.entityType &&  el.entityValue.trim();
     }));
    this.merchantRequest.eventSubscriptionId = this.data;
    this.merchantRequest.db2EventProductName = MessageConstants.RAFT_REAL_TIME_KAFKA;
    this.merchantRequest.updatedBy = this.userID.length <= 8 ? this.userID :"EVNT_USR";
    this.merchantRequest.merchantEntity = items;
    this.spinner.show();
    this.eventService.updateMerchantHierarchy(this.merchantRequest).subscribe(
      (result) => {
        this.matDailog.closeAll();
        this.spinner.hide();
        if (result == null) {

          this.loadingMessages[2] = MessageConstants.INTERNAL_SERVER_ERROR
          this.errorMsgDialog(this.loadingMessages[2]);

        } else if (result.length > 0) {

          this.hierarchyDataList = result.map(e => ({
            ...e,
            entityType: EntityTypeEnum[e.entityType],
          }));;
          this.inputHierarchyForm.reset();
          this.openPopUpWithDetails(this.merchantRequest.eventSubscriptionId);
        }
      },
      (error) =>{
        this.spinner.hide();
        if (error.status) {

         this.loadingMessages[2] = this.errorService.gatewayError(error);
          if(this.loadingMessages[2].includes("invalid entityValue")){
            
            this.loadingMessages[2] = "One Or More Entity Value is Incorrect";
           }
          this.errorMsgDialog(this.loadingMessages[2]);
          if (this.loadingMessages[2] === MessageConstants.TIME_OUT) {
            window.location.reload();
          }
        } 
      }
    );
    
    }
  }

  getMerchantHierarchy(eventSubscriptionId: any) {
    this.spinner.show();
    this.eventService.getMerchantHierarchy(eventSubscriptionId).subscribe(
      (result) => {
        if (result == null) {
          this.spinner.hide();

        } else if (result.length > 0) {

          this.hierarchyDataList = result.map(e => ({
            ...e,
            entityType: EntityTypeEnum[e.entityType],
          }));;
          this.spinner.hide();

      }
    },
    (error) =>{
      if (error.status) {
        this.loadingMessages[2] = this.errorService.gatewayError(error);
        this.errorMsgDialog(this.loadingMessages[2]);
        if (this.loadingMessages[2] === MessageConstants.TIME_OUT) {
          window.location.reload();
        }
      } 
    }
      );
  }

  enableEditHierarchy() {
    this.enableButtons = true;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).disabled = true;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.remove("primarybtn");
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.add("disabledButton");
  }

  disableEditHierarchy() {
    this.enableButtons = false;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).disabled = false;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.add("primarybtn");
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.remove("disabledButton");
  }

  deleteHierarchyData(hierarchy: MerchantHierarchy, index) {
      this.openDialog(hierarchy);
  }

  closePopUp() {
    this.matDailog.closeAll();
  }

  async showAlertOnDelete(hierarchy) {
      const alert = await this._alertController.create({
        header: 'Alert Message',
        cssClass: 'mat-dialogue-title',
        message: MessageConstants.HIERARCHY_DELETE_ALERT,
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              this.openPopUpWithDetails(hierarchy.eventSubscriptionId);
            },
            cssClass: 'secondarybtn'
          },
          {
            text: 'Yes',
            cssClass: 'primarybtn',
            handler: () => {
              this.deleteHierarchy(hierarchy);
            }
          }
        ],
        backdropDismiss: false,
      });
      await alert.present();
  }

  openDialog(hierarchy) {
    const dialogRef = this.matDailog.open(ConfirmationDialog,{
      data:{
        message: MessageConstants.HIERARCHY_DELETE_ALERT,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteHierarchy(hierarchy);
      }
    });
  }

  openPopUpWithDetails(eventId: any) {

    this.spinner.show();
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.matDailog.open(PopupViewMerchantHierarchy, {
      panelClass: 'merchant-hierarchy-dialog-container',
      backdropClass: 'custom-backdrop',
      autoFocus: false,
      closeOnNavigation: true,
      data: eventId,
      disableClose: true,
      hasBackdrop: true});
    dialogRef.afterClosed().subscribe(result => {
         
    });

  }

  deleteHierarchy(hierarchy: MerchantHierarchy) {
    const subsId = hierarchy.eventSubscriptionId;
      const index: number = this.hierarchyDataList.indexOf(hierarchy);
    if (this.hierarchyDataList.length == 1) {

      this.errorMsgDialog("At least one Hierarchy Detail is required. Kindly add a new Hierarchy Detail before deleting an existing Hierarchy Detail")
      
    } else {

          this.spinner.show();
          this.eventService
            .deleteMerchantHierarchy(hierarchy.id)
            .subscribe(() => {
                this.spinner.hide();
                if (index !== -1) {
                  this.hierarchyDataList.splice(index, 1);
                }
               // this.openPopUpWithDetails(subsId);
              },
              (error) => {
                this.spinner.hide();
                if (error.status) {
                  this.loadingMessages[2] = this.errorService.gatewayError(error);
                  this.errorMsgDialog(this.loadingMessages[2]);
                  if (this.loadingMessages[2] === MessageConstants.TIME_OUT) {
                    window.location.reload();
                  }
                } 
        });
      }
  }

  errorMsgDialog(msg){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [{eventErrorMessage: 6,errorMessage: msg}];
    dialogConfig.backdropClass = 'custom-backdrop';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const dialogRef = this.matDailog.open(DialogAlertContentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  successMsgDialog(msg){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [{successMessage: 1,successText: msg}];
    const dialogRef = this.matDailog.open(DialogAlertContentComponent, dialogConfig);
    return dialogRef;
  }

  isEntityValueValid(i) {

    return (<FormArray>this.inputHierarchyForm.get('items')).controls[i].invalid && 
    (<FormArray>this.inputHierarchyForm.get('items')).controls[i].value.entityValue != '' &&
    (<FormArray>this.inputHierarchyForm.get('items')).controls[i].touched && (<FormArray>this.inputHierarchyForm.get('items')).controls[i].dirty;
  }
  ngOnDestroy() {}
}
