
import { Injectable } from '@angular/core';
import { Observable, of  } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { ENDPOINTS } from '@app/endpoints';
import { ApiService } from './api.service';
import { SharedService } from '.';

@Injectable({
    providedIn: 'root'
})
export class EventService extends ApiService {


public tagEventsToSubscriber(params): Observable<any>{
    return this.post( ENDPOINTS.POST_EVENTS_SUBSCRIBERS, params, SharedService.subscriberInClaims);
}

public updateEventSubscriber(params): Observable<any> {
    return this.patch( ENDPOINTS.POST_EVENTS_SUBSCRIBERS, params, SharedService.subscriberInClaims);
}

public updateMerchantHierarchy(params): Observable<any> {
    return this.post(ENDPOINTS.MERCHANT_HIERARCHY, params, SharedService.subscriberInClaims);
}

public getA4EMerchantHierarchy(webhookEventId: any): Observable<any> {
    return this.get(ENDPOINTS.AWS_WEBHOOK_EVENTS + '/' + webhookEventId);
}

public putWebhookEvents(webhookEventId: any, params): Observable<any> {
    return this.put(ENDPOINTS.AWS_WEBHOOK_EVENTS +'/' + webhookEventId, params);
}

public getMerchantHierarchy(eventSubscriptionId: any): Observable<any> {
    const params = new HttpParams()
    .set('eventSubscriptionId', eventSubscriptionId)
    return this.get(ENDPOINTS.MERCHANT_HIERARCHY, params);
}

public deleteMerchantHierarchy(id: any): Observable<any> {
        const url = ENDPOINTS.MERCHANT_HIERARCHY +'/' +id;
        const params: any = '';
        return this.delete(url, params, SharedService.subscriberInClaims);
}

}
