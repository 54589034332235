import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public ver:string;

  constructor() { }

  get version():string{
    return this.ver;
  }
  set version(ver: string){
    this.ver = ver;
  }
}