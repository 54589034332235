import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Events } from 'src/app/services/events';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'page-assignedto-teams',
  templateUrl: 'popover-assignedto.html',
  styleUrls: ['popover-assignedto.scss'],
})
export class PopoverAssignedToComponent implements OnInit {

  @Input() assignedToList: any[] = [];
  @Input() selectedAssignedToList: any[] = [];
  @Output() teamValChanged: EventEmitter<any> = new EventEmitter<any>();

  allSelected: boolean;
  allNtofnStatus: any[] = [];
  checkboxControls: boolean[] = [];
  teamCount: number;
  pageSource: string;

  constructor(public sharedService: SharedService, public events: Events) { }

  ngOnInit(): void {

    this.allNtofnStatus = Array.from(this.assignedToList);
    for (const teamMember of this.allNtofnStatus) {
      this.checkboxControls[teamMember.name] = false;
    }
    for (const teamMember of this.selectedAssignedToList) {
      this.checkboxControls[teamMember.name] = true;
    }
    this.checkAllSelected();
    this.setCustomPropValue();
  }

  teamChanged(teamMember: any): void {
    const index = this.selectedAssignedToList.indexOf(teamMember.name);
    if (index > -1) {
      this.selectedAssignedToList.splice(index, 1);
    } else {
      this.selectedAssignedToList.push(teamMember);
    }
    this.sharedService.filters.isTeamEmpty = false;
    this.sharedService.filters.assignedTo = this.selectedAssignedToList;
    this.sharedService.filters.isTeamEmpty = true;
    this.checkAllSelected();
    this.publishEvent();
  }

  selectAll(): void {
    if (this.selectedAssignedToList.length === this.allNtofnStatus.length) {
      alert('if')
      this.allNtofnStatus.forEach(teamMember => {
        this.checkboxControls[teamMember.name] = false;
      });
    } else {
      this.allNtofnStatus.forEach(teamMember => {
        if (this.selectedAssignedToList.indexOf(teamMember.name) < 0) {
          alert('else')
          this.selectedAssignedToList.push(teamMember);
        }
        this.checkboxControls[teamMember.name] = true;
      });
    }
    this.publishEvent();
  }

  checkAllSelected(): void {
    this.allSelected = this.allNtofnStatus.length === this.selectedAssignedToList.length;
  }

  publishEvent(): void {

    this.events.publish(
      'assignedToFilter:updated',
      this.selectedAssignedToList,
      this.pageSource,
    );
  }

  setCustomPropValue(): void {
    document.body.style.setProperty('--bgColorValue', this.sharedService.primaryColor);
  }

}
