import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

/**
 * @name Events
 * @description
 * Custom events service to emulate Ionic 3 events, which is a pub-sub
 * style event system for sending and responding to application-level
 * events across your app.
 * @author Jordyn Tucker
*/
@Injectable({
  providedIn: 'root'
})
export class Events {

  private channels: { [key: string]: Subject<any>; } = {};

  /**
   * Subscribe to an event topic. Events that get posted to that topic will trigger the provided observer.
   *
   * @param {string} topic the topic to subscribe to
   * @param {Function} observer the event observer
   */
  subscribe(topic: string, observer: (...args: any[]) => void): Subscription {
    if (!this.channels[topic]) {
      this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].subscribe(observer);
  }

  /**
   * Publish an event to the given topic. 
   *
   * @param {string} topic the topic to publish to
   * @param {any} data the data to send as the event
   */
  publish(topic: string, ...data: any[]): void {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }
    subject.next(data);
  }

  /**
   * Unsubscribe from the given topic. Observers provided on subscription will no longer receive
   * events published to this topic.
   * 
   * @param {string} topic the topic to unsubscribe from
   */
  unsubscribe(topic: string): null {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }
    subject.complete();
    delete this.channels[topic];
  }

}