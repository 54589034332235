import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from './services';
import { AuthService } from '@ruf/idp-auth';
@Injectable({
  providedIn: 'root',
})
export class BasicGuard implements CanActivate {
  constructor(private _router: Router, private _sharedService: SharedService, private auth: AuthService) { }
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin();
  }
  checkLogin(): boolean {
    let isAuthenticated = this._sharedService.getIDToken();
    if (!isAuthenticated || !Object.keys(isAuthenticated).length) {

      this.auth.login();
      return true;
    }
    else {
      return true;
    }
  }
}
