import { HttpClient, HttpRequest } from '@angular/common/http';
import {
  Inject,
  Injectable,
  InjectionToken,
  OnDestroy,
  Optional,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthConfig,
  OAuthInfoEvent,
  OAuthModuleConfig,
  OAuthService,
  OAuthStorage,
  OAuthSuccessEvent,
  TokenResponse,
  UserInfo,
  OAuthEvent,
  LoginOptions,
} from 'angular-oauth2-oidc';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthGuardData } from './auth-guard.service';
import { AuthWindowRef } from './util';

export const INITIAL_PATH_STORE_KEY = 'ruf-idp-auth-initial-path';
export const TOKEN_RECEIVED_EVENT = 'token_received';
export const TOKEN_EXPIRES_EVENT = 'token_expires';
export const DISCOVERY_DOC_LOADED_EVENT = 'discovery_document_loaded';
export const EXPIRES_IN_TOKEN = 'expires_at';
export const ACCESS_TOKEN_TIME = 'access_token_stored_at';
export const CLAIMS_TOKEN = 'id_token_claims_obj';
export const ID_TOKEN = 'id_token';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const STATE_TOKEN = 'session_state';
/* eslint-disable @typescript-eslint/naming-convention */
export type REFRESH_TOKEN_TYPE = 'access_token' | 'id_token' | 'any';

export interface AuthenticationSuccess {
  initialPath: string; // path the app is free to follow to return to the original path were login was invoked
}

export class RufAuthConfig extends AuthConfig {
  interceptUrls?: string[]; // urls to be intercepted. 'origin' is special value
  authorizationClaimProperty?: string; // points to the claim property name that holds a comma-separated list of authorizations
  unauthorizedRoute?: string[];
  idpApiKey?: string;
  autoRefresh?: boolean; // whether tokens should be automatically refreshed upon expiration
  listenToToken?: string; // if autoRefresh is true, which token expiry (access or id token) to be considered
  encrypted?: boolean;
  loginOptions?: LoginOptions;
}

export const AUTH_NO_INTERCEPT_HEADER = 'X-Auth-No-Intercept';

export const AUTH_CONFIG = new InjectionToken<RufAuthConfig>('AUTH_CONFIG');

// Get origin e.g., http://localhost:4200
export const origin = (winref?) => {
  const win = winref || window;
  return (
    win &&
    win.location &&
    win.location.protocol +
      '//' +
      win.location.hostname +
      (win.location.port && ':' + win.location.port)
  );
};

export const isRelativeUrl = (url: string): boolean => {
  const absoluteUrlRegExp = /^https?:\/\//i;
  return !absoluteUrlRegExp.test(url);
};

// Get resource server configuration from interceptUrls provided in 'RufAuthConfig'
// https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/working-with-httpinterceptors.html
export const resourceServerConfig = (
  interceptUrls: string[] = [],
  win?
): {
  customUrlValidation?: (url: string) => boolean;
  allowedUrls?: string[];
  sendAccessToken: boolean;
} => {
  const resourceConfig: any = {};
  // if interceptUrls is not provided, send acess token for every request
  // This behavior is consistent with "angular-oauth2-oidc" in that if "allowedUrls" is not provided to resourceConfig,
  // it sends token in every request
  if (!interceptUrls || !interceptUrls.length) {
    return { sendAccessToken: true };
  }
  const allowedUrls = interceptUrls.filter((u) => u !== 'origin');
  const interceptOrigin = allowedUrls.length !== interceptUrls.length;
  if (interceptOrigin) {
    resourceConfig.customUrlValidation = (url: string) =>
      url.startsWith(origin(win)) ||
      allowedUrls.some((allowedUrl) => url.startsWith(allowedUrl)) ||
      isRelativeUrl(url); // if url does not contain domain information e.g. "/api/rest/users";
    resourceConfig.sendAccessToken = true;
  } else {
    if (allowedUrls && allowedUrls.length) {
      resourceConfig.allowedUrls = allowedUrls;
      resourceConfig.sendAccessToken = true;
    }
  }
  return resourceConfig;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  isLoggedIn: boolean;
  subscription = Subscription.EMPTY;
  public initialized = new Promise<boolean>((resolve) => {
    this._resolveInitialized = resolve;
  });

  /* eslint-disable @typescript-eslint/member-ordering */
  private _discoveryDoc;
  private _claimsSubject: Subject<any> = new ReplaySubject<any>(1);
  public authorizations$: Observable<Set<string>> = this._claimsSubject.pipe(
    map(
      (claims) =>
        (claims[this.cfg.authorizationClaimProperty] &&
          new Set(claims[this.cfg.authorizationClaimProperty].split(', '))) ||
        new Set()
    )
  );

  private _resolveInitialized;

  // replay just the last value (current id token)
  private _idTokenSubject: Subject<string> = new ReplaySubject<string>(1);
  private _tokensSubject: Subject<TokenResponse> = new ReplaySubject<TokenResponse>();
  private _tokenExpirationSubject: Subject<number> = new Subject<number>();
  private cfg: RufAuthConfig;

  constructor(
    @Optional()
    @Inject(AUTH_CONFIG)
    cfg: RufAuthConfig,
    private oAuthService: OAuthService,
    @Optional()
    @Inject(AuthWindowRef)
    private win,
    private router: Router,
    @Optional() private http: HttpClient,
    private storage: OAuthStorage,
    private moduleConfig: OAuthModuleConfig
  ) {
    this.configureAuth(cfg);

    // Listen to TOKEN_RECEIVED_EVENT event and publish new tokens
    this.subscription = this.oAuthService.events
      .pipe(
        filter(
          (e) =>
            e.type === TOKEN_RECEIVED_EVENT ||
            e.type === TOKEN_EXPIRES_EVENT ||
            e.type === DISCOVERY_DOC_LOADED_EVENT
        )
      )
      .subscribe((event) => {
        switch (event.type) {
          case TOKEN_RECEIVED_EVENT:
            // publish id token, claims and token response
            this.publishTokens();
            break;
          case TOKEN_EXPIRES_EVENT:
            const e = event as OAuthInfoEvent;
            if (e.info === this.cfg.listenToToken) {
              this._tokenExpirationSubject.next(this.tokenExpirationTime());
            }
            break;
          case DISCOVERY_DOC_LOADED_EVENT:
            const docEvent = event as OAuthSuccessEvent;
            if (docEvent && docEvent.info) {
              this._discoveryDoc = docEvent.info.discoveryDocument;
            }
            break;
        }
      });
  }

  setup(config: RufAuthConfig) {
    this.configureAuth(config);
    const resourceConfig = resourceServerConfig(config.interceptUrls, this.win);
    if (resourceConfig) {
      this.moduleConfig.resourceServer.sendAccessToken = !!resourceConfig.sendAccessToken;
      if (resourceConfig.allowedUrls) {
        this.moduleConfig.resourceServer.allowedUrls =
          resourceConfig.allowedUrls;
      }
      if (resourceConfig.customUrlValidation) {
        this.moduleConfig.resourceServer.customUrlValidation =
          resourceConfig.customUrlValidation;
      }
    }
  }

  async config() {
    await this.initialized;
    return { ...this.cfg };
  }

  get unauthorizedRoute() {
    return this.cfg.unauthorizedRoute;
  }

  get discoveryDocument() {
    return this._discoveryDoc;
  }

  get idToken$() {
    return this._idTokenSubject.asObservable();
  }

  get tokens$() {
    return this._tokensSubject.asObservable();
  }

  get claims$() {
    return this._claimsSubject.asObservable();
  }

  get tokenExpiration$() {
    return this._tokenExpirationSubject.asObservable();
  }

  get userInfo(): Promise<UserInfo> {
    return this.oAuthService.loadUserProfile() as Promise<UserInfo>;
  }

  createAuthenticatedRequest(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.oAuthService.getAccessToken();
    if (token) {
      return request.clone({
        /* eslint-disable @typescript-eslint/naming-convention */
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    return request;
  }

  /**
   * Loads discovery document and calls login if needed. Also checks if user has required
   * authorizations to proceed.
   *
   * @param auth - authorization provided using router data
   */
  login(auth?: AuthGuardData): Promise<boolean> {
    return this.initialized.then((_) => {
      if (!this.cfg) {
        throw new Error('[AuthService]: No AuthConfig provided.');
      }
      const isAuthenticated = this.oAuthService.hasValidAccessToken();
      if (!isAuthenticated) {
        return this.oAuthService
          .loadDiscoveryDocumentAndLogin(this.cfg.loginOptions)
          .then(() => {
            if (this.oAuthService.hasValidAccessToken()) {
              const claims = this.oAuthService.getIdentityClaims();
              return this.isValidClaim(claims, auth);
            }
            return false;
          });
      } else {
        // already logged in
        // check for authorizations
        const claims = this.oAuthService.getIdentityClaims();
        if (auth && !this.hasAuthorizations(claims, auth)) {
          this.isLoggedIn = false;
          // navigate to unauthorized route
          this.navigateToUnauthorizedRoute();
          return false;
        }
        if (
          this.cfg.autoRefresh &&
          this.oAuthService.getAccessTokenExpiration() < new Date().getTime()
        ) {
          this.logout();
          return false;
        }
        // publish id token, claims and token response
        this.publishTokens();
        this.isLoggedIn = true;
        return true;
      }
    });
  }

  /**
   * logout of IdP and remove tokens from storage.
   *
   * @param deleteIDPSession - if true, IdP session is cleared. Otherwise this method will only delete
   * tokens from the store.
   */
  logout(deleteIDPSession?: boolean): Promise<boolean> {
    if (deleteIDPSession) {
      return this.http
        .delete(`${this.cfg.issuer}/rest/1.0/sessions`, {
          headers: this.headers,
          withCredentials: true,
        })
        .toPromise()
        .then((_) => {
          this.oAuthService.logOut(true);
          this.isLoggedIn = false;
          if (this.cfg.logoutUrl) {
            this.oAuthService.openUri(this.cfg.logoutUrl);
          }
          return true;
        })
        .catch((err) => {
          console.error('Error in logout ', err);
          return false;
        });
    }
    this.oAuthService.logOut(true);
    this.isLoggedIn = false;
    if (this.cfg.logoutUrl) {
      this.oAuthService.openUri(this.cfg.logoutUrl);
    }
    return Promise.resolve(true);
  }

  /**
   * Checks if user has all / some authorizations to proceed
   *
   * @param claims - identity claims
   * @param data - router data that contains authorizations required to proceed
   */
  hasAuthorizations(claims, data?: AuthGuardData): boolean {
    if (!claims) {
      // no 'claims' means this is an encrypted id token. Authorization won't be checked in that case.
      return true;
    }
    const authorizationClaimProperty =
      this.cfg.authorizationClaimProperty || 'AuthorizationList';
    let userAuthorizations = claims[authorizationClaimProperty];
    userAuthorizations =
      (userAuthorizations && new Set(userAuthorizations.split(', '))) ||
      new Set();

    const routeAuthorizations =
      data && data.authorizations ? [].concat(data.authorizations) : [];
    const op = data && data.require === 'any' ? 'some' : 'every';

    return routeAuthorizations[op]((a) => userAuthorizations.has(a));
  }

  hasValidIdToken(): boolean {
    return this.oAuthService.hasValidIdToken();
  }

  hasValidAccessToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  tokenExpirationTime(): number {
    return this.cfg.listenToToken === 'id_token'
      ? this.oAuthService.getIdTokenExpiration()
      : this.oAuthService.getAccessTokenExpiration();
  }

  refreshToken(): Promise<TokenResponse> {
    return this.oAuthService.refreshToken();
  }

  set initialPath(path: string) {
    const storage = this.win ? this.win.localStorage : localStorage;
    if (path === null) {
      storage.removeItem(INITIAL_PATH_STORE_KEY);
    } else {
      storage.setItem(INITIAL_PATH_STORE_KEY, path);
    }
  }

  get initialPath(): string {
    const storage = this.win ? this.win.localStorage : localStorage;
    return storage.getItem(INITIAL_PATH_STORE_KEY);
  }

  _clearIdToken() {
    this.storage.removeItem(ID_TOKEN);
    this.storage.removeItem(ACCESS_TOKEN);
  }

  async shouldIntercept(request: HttpRequest<any>): Promise<boolean> {
    if (request.headers.has(AUTH_NO_INTERCEPT_HEADER)) {
      // we will handle errors coming from IdP
      return false;
    }
    return Boolean(
      this.cfg.interceptUrls.find((h) => request.url.startsWith(h))
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get headers() {
    /* eslint-disable @typescript-eslint/naming-convention */
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      [AUTH_NO_INTERCEPT_HEADER]: 'yes',
    };
    if (this.cfg.idpApiKey) {
      headers['X-SunGard-IdP-API-Key'] = this.cfg.idpApiKey;
    }
    return headers;
  }

  get events$(): Observable<OAuthEvent> {
    return this.oAuthService.events;
  }

  navigateToUnauthorizedRoute() {
    if (this.cfg.unauthorizedRoute) {
      this.router.navigate(this.cfg.unauthorizedRoute);
    }
  }

  private publishTokens() {
    /* eslint-disable @typescript-eslint/naming-convention */
    this._idTokenSubject.next(this.storage.getItem(ID_TOKEN));
    if (!this.cfg.encrypted) {
      this._claimsSubject.next(this.storage.getItem(CLAIMS_TOKEN));
    }

    this._tokensSubject.next({
      access_token: this.storage.getItem(ACCESS_TOKEN),
      id_token: this.storage.getItem(ID_TOKEN),
      expires_in: parseInt(this.storage.getItem(EXPIRES_IN_TOKEN), 10),
      refresh_token: this.storage.getItem(REFRESH_TOKEN),
      token_type: this.cfg.responseType,
      scope: this.storage.getItem(STATE_TOKEN),
    });
  }

  private removeTrailingSlash(str: string) {
    if (str.endsWith('/')) {
      return str.substring(0, str.length - 1);
    }
    return str;
  }

  private configureAuth(config: RufAuthConfig) {
    if (config) {
      // set default values
      config.issuer = this.removeTrailingSlash(config.issuer);
      config.authorizationClaimProperty =
        config.authorizationClaimProperty || 'AuthorizationList';
      config.responseType = config.responseType || 'code';

      this.cfg = config;
      this.oAuthService.configure(this.cfg);
      if (this.cfg.autoRefresh) {
        this.oAuthService.setupAutomaticSilentRefresh(
          {},
          (this.cfg.listenToToken || ACCESS_TOKEN) as REFRESH_TOKEN_TYPE
        );
      }
      this._resolveInitialized(true);
    }
  }

  private isValidClaim(claims, auth): boolean {
    if (auth && this.hasAuthorizations(claims, auth)) {
      // publish id token, claims and token response
      this.publishTokens();
      // Authentication complete. Navigate to the original path.
      this.router.navigateByUrl(this.initialPath);
      this.isLoggedIn = true;
      return true;
    } else {
      this.isLoggedIn = false;
      // navigate to unauthorized route
      this.navigateToUnauthorizedRoute();
      return false;
    }
  }
}
