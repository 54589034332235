

//child
     interface SubscriberList {
        subscriberName: string;
        subscriberCode: string;
        description: string;
        appId: string;
        signType: string;
        signKeyName: string;
        flag: boolean;
    }

    //parent
     interface RootObject {
        subscriberList: SubscriberList[];
    }

    
    export class NotfnSearch {
        version:any;
        subscriberId: any;
        eventType: any;
        fromDate: string;
        toDate: string;
        status: any;
        searchKey:string;
        searchValue:number;
        notificationId:number;
        merchantId:number;
        constructor() {
            this.version='v1';
            this.subscriberId = [];
            this.eventType = [];
            this.fromDate = '';
            this.toDate = '';
            this.status = [];
            this.searchKey = null;
            this.searchValue = null;
            this.notificationId=null;
            this.merchantId=null;
         };
    }
