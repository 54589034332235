import { Injectable } from '@angular/core';
import { Observable  } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { ENDPOINTS } from '@app/endpoints';
import { ApiService } from './api.service';
import { SharedService } from '.';

@Injectable({
    providedIn: 'root'
})
export class ContactService extends ApiService {
    private subscriberInClaims: any = SharedService.subscriberInClaims;
    public saveContact(contact : any): Observable<any> {
        return this.post(ENDPOINTS.POST_CONTACT, contact, '');
    }

    public updateContact(contact : any): Observable<any> {
        return this.patch(ENDPOINTS.POST_CONTACT, contact, SharedService.subscriberInClaims);
    }

    public tagContact(contactId : any, subscriberCode: any, userID: any = ''): Observable<any> {
        const url = `${ENDPOINTS.TAG_CONTACT}/${contactId}/subscriber/${subscriberCode}?insertedBy=${userID}`;
        const params: any = '';
        return this.post(url, params, SharedService.subscriberInClaims);
    }

    public getContactTypes(): Observable<any> {
        return this.get(ENDPOINTS.GET_CONTACTTYPE, '');
    }

    public untagContact(contactId : any, subscriberCode: any): Observable<any> {
        const url = ENDPOINTS.UNTAG_CONTACT +'/' +contactId+ "/subscriber/"+subscriberCode;
        const params: any = '';
        return this.delete(url, params, '');
    }

    public deleteContact(contactId : any): Observable<any> {
        const url = ENDPOINTS.DELETE_CONTACT +'/' +contactId;
        const params: any = '';
        return this.delete(url, params, SharedService.subscriberInClaims);
    }

    public contactSearch(subscriberCode: any): Observable<any> {
        const params = new HttpParams()
        .set('subscriberCode', subscriberCode)
        .set('subscribersInClaim', SharedService.subscriberInClaims);
       return this.get(ENDPOINTS.POST_CONTACT, params);
    }

}
