import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';


@Component({
    selector: 'checkbox-dropdown',
    templateUrl: './checkbox-dropdown.component.html',
    styleUrls: ['./checkbox-dropdown.component.scss'],
})
export class CheckBoxDropDown implements OnInit {

    @Input()
    private data: any;

    unfilteredList = [];
    filteredList = [];
    selectedType = '';

    constructor(private _popoverController: PopoverController) { }

    ngOnInit() {
        //alert(JSON.parse(JSON.stringify(this.data)));
        this.filteredList = this.data.filter(item => {
            return item.flag;
        });
        this.unfilteredList = this.data;
    }

    findSelected(selected) {
        const a = this.filteredList.filter(item => item.subscriberName === selected.subscriberName);
        return (a && a.length > 0) ? true : false;
    }

    selectionChanged1(event, selected: any) {
    }

    selectionChanged(event, selected: any) {
        if (!selected) {
            return;
        }

        if (selected == 'all') {
            this.filteredList = this.filteredList.length === this.unfilteredList.length ? [] : this.unfilteredList;
            this.filteredList.map(item => {
                item.flag = true;
            });
            if (this.filteredList.length == 0) {
                this.unfilteredList.map(item => {
                    item.flag = false;
                });
            }
        }
        else {

            const a = this.filteredList.filter(item => item.subscriberName === selected.subscriberName);
            if (a.length === 0) {
                selected.flag = true;
                this.filteredList.push(selected);
            } else {
                this.filteredList = this.filteredList.filter(item => {
                    item.flag = item.subscriberName !== selected.subscriberName;
                    return item.flag;
                });
            }
            this.findSelected(selected);
        }
    }

    onClosePopup() {
        this._popoverController.dismiss(this.filteredList);
    }


    checked = [];
    //Adds the checkedbox to the array and check if you unchecked it
    addCheckbox(event, checkbox : String) { 
        if ( event.checked ) {
          this.checked.push(checkbox);
        } else {
          let index = this.removeCheckedFromArray(checkbox);
          this.checked.splice(index,1);
        }
      }
    
      //Removes checkbox from array when you uncheck it
      removeCheckedFromArray(checkbox : String) {
        return this.checked.findIndex((category)=>{
          return category === checkbox;
        })
      }
    
      //Empties array with checkedboxes
      emptyCheckedArray() {
        this.checked = [];
      }
    
     getCheckedBoxes() {
       //Do whatever
       console.log(this.checked);
     }

}