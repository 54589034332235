import { Component, Input } from '@angular/core';

/**
 * Generated class for the PageLoaderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'page-loader',
  templateUrl: 'preloader.component.html'
})
export class PreloaderComponent {

  @Input() loaderText: string;
  loaderImgPath: string;

  ngOnInit() {
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      this.loaderImgPath = "assets/imgs/loaderGif.gif";
    }
    else {
      this.loaderImgPath = "assets/imgs/loader.svg";
    }
  }
}
