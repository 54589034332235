import { Component, OnInit } from '@angular/core';
import { SessionManagementServiceProvider } from 'src/app/services/session-management-service';
import { ENV } from '@app/env';
import { ENDPOINTS } from '@app/endpoints';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AuthService } from '@ruf/idp-auth';
@Component({
  selector: 'timeout-warning',
  templateUrl: 'timeout-warning.html',
  styleUrls: ['timeout-warning.scss']
})
export class TimeoutWarningComponent implements OnInit {

  countdownSeconds = ENV.SESSION_WARNING_EXPIRY_TIME;
  timer: any;

  constructor(private _router: Router, private modalController: ModalController, private popoverController: PopoverController, private sessionManagementService: SessionManagementServiceProvider, private navCtrl: NavController,
    private sharedService: SharedService, private authService: AuthService) { }
  ngOnInit(): void {
    this.startTimer();
    var modal = document.getElementById("myModal")!;
    modal.style.display = "block";
  }

  async closePopovers(): Promise<any> {
    const popover = await this.popoverController.getTop();
    if (popover) {
      return popover.dismiss();
    }
  }

  startTimer(): void {
    this.timer = setInterval(() => {
      --this.countdownSeconds;
      if (this.countdownSeconds < 0) {
        this.countdownSeconds = 0;
      }
      if (this.countdownSeconds == 1) {
        if (ENV.USING_IDP) {
          setTimeout(() => {
            localStorage.setItem('isUserLoggedOut', 'true');
            this.authService.logout(true).then((value) => {
              setTimeout(() => {
                window.location.href = localStorage.getItem('logoutUrl');
              })
            }, err => { });
          }, 10)
          localStorage.clear();
          sessionStorage.clear();
        } else {
          this.sharedService.removeAuthorization();
          this.navCtrl.navigateRoot(['./dashboard']);
        }
      }
    }, 1000);
  }

  resetTimer(): void {
    this.closeModal();
    clearInterval(this.timer);
    this.sessionManagementService.displaySessionTimeoutWarning = false;
    this.sharedService.invokeKeepAlive().subscribe();
  }

  logOut(): void {
    this.sessionManagementService.logout();
  }

  closeModal() {
    var modal = document.getElementById("myModal")!;
    modal.style.display = "none";
  }

}
