export enum MessageConstants {
    RETRY_HINT = 'Select atleast one record to retry',
    VALID_DATE_FORMAT = 'Please select a valid date in YYYY-MM-DD format',
    ONLY_NUMERIC_VALUE = 'Only numeric value allowed',
    EXCEEDED_MAX_LENGTH = 'Maximum number of characters exceeded',
    INVALID_POST_URL = 'Please enter valid Post URL',
    POST_URL_EXCEEDS_1000 = 'Post URL exceeds max length of 1000 chars',
    MAX_EXCEEDS_2147483647 = 'Exceeds max value of 2147483647',
    NOTIFICATION_ID_LIMIT = 'Notification ID cannot exceed 36 characters',
    NO_RECORDS_FOUND = 'No records found',
    PLEASE_WAIT_LOADING = 'Please wait, loading...',
    HTTP_STATUS_ERROR = 'Http status error',
    SESSION_TIMED_OUT = 'Session timed out',
    UNABLE_TO_PROCESS = 'Unable to process the request, try again later or contact the system administrator',
    INTERNAL_SERVER_ERROR = 'Internal server error, try again later or contact the system administrator',
    NOT_FOUND = 'Not Found',
    SUB_RESPONSE_NOT_JSON = 'Subscriber response is not a valid JSON. It will be downloaded in text format',
    NEW_NOTIFICATION_CREATED = 'New Notification(s) Created : ',
    NOTIFICATIONS_PUSHED_RETRY = 'Notification(s) Pushed to Retry Queue : ',
    NOTIFICATION_LENGHT_EXC = 'Notification ID length exceeded',
    INVALID_NOTIFICATION_ID = 'Invalid Notification ID provided',
    NOTIFICATION_ID_10_LIMIT = 'Notification ID cannot exceed 10 characters',
    NO_RECORDS_FOR_CRITERIA = 'No records found for the given search criteria',
    TERM_ID_INTEGER = 'Terminal ID should be integer only',
    LEASE_ID_INTEGER = 'Lease ID should be integer only',
    NOTIFICATION_INCONSISTANT_DATA = 'Cannot perform Notification Search because of inconsistent date input',
    RECORDS_SELECTED = 'Records Selected',
    SELECT_DATE_90_DAYS = 'Select start date within 90 Days of current date',
    NOTIFICATIONS_RETRY = 'Notifications not pushed to Retry queue',
    LOGGING_IN = 'Logging you in...',
    FIELD_REQUIRED = 'This field is required',
    VALID_MAILID = 'Please provide valid Email address',
    DELETE_CONFIRMATION = 'Are you sure want to delete?',
    ATLEAST_ONE_INPUT_REQ = 'Atleast one input is required',
    SPEC_CHAR_NOT_ALLOW = 'Special Character is not allowed',
    GREATER_THAN_10CHAR = 'Should not be greater than 10 characters',
    SUB_CODE_ALREADY_EXIST = 'Subscriber code already exists',
    SUB_NAME_GREATER_50 = 'Subscriber Name should not be greater than 40 characters',
    SUB_NAME_START_WITH_SPACE = 'Subscriber Name should not start with blank spaces',
    VALUE_START_WITH_SPACE = 'Value must begin with a letter/number and not spaces',
    DESC_START_WITH_SPACE = 'Description should not start with blank spaces',
    SIGN_IN_KEY_GREATER_25 = 'Sign Key should not be greater than 25 characters',
    CAPTURE_SIGNIN_KEY = 'Kindly capture the sign key',
    DESC_KEY_GREATER_100 = 'Description should not be greater than 100 characters',
    APPID_GREATER_50 = 'App Id should not be greater than 50 characters',
    SUB_ALREADY_EXIST = 'Subscriber already exists',
    DATA_SAVED_SUCCESSFULLY = 'Subscriber saved successfully',
    TIME_OUT = 'Time out',
    UNABLE_CREATE_CONTACT = 'Unable to create the contact, kindly contact the system administrator',
    CONT_TAGGED_SUCCESS = 'Contact has been successfully tagged for the subscriber',
    UNABLE_FIND_EVENT_SUBID = 'Unable to find EventSubscriptionId for given subscriberCode',
    CONTACT_ADD_ERROR = 'Contact cannot be added without event subscription for the subscriber. Atleast one event should be subscribed',
    CONTACT_DELETE_ALERT = 'Are you sure you want to delete this contact?',
    HIERARCHY_DELETE_ALERT = 'Are you sure you want to delete this Hierarchy Details?',
    RAFT_REAL_TIME_KAFKA = 'RAFT_REAL_TIME_KAFKA',
    TECH_BUISNESS_OPS_VALIDATION = 'Atleast one Tech Ops and Business Ops Contact is required. Kindly add a new contact before deleting a existing contact',
    UNABLE_DELETE_CONTACT = 'Unable to delete the contact, kindly contact the system administrator',
    UNABLE_DELETE_HIERARCHY = 'Unable to delete the entity details, kindly contact the system administrator',
    UNABLE_MODIFY_CONTACT = 'Unable to modify the contact, kindly contact the system administrator',
    UNABLE_TO_GET_CONTACT = 'Unable to get the contacts, kindly contact the system administrator',
    UNABLE_TO_REGISTER_EVENT = 'Unable to register the event, kindly contact the system administrator',
    EVT_SUCCESSFULLY_REG = 'Event has been successfully registered for the subscriber',
    FORM_RESET_ALERT = 'Confirm reset of the form? Click &quot;Yes&quot; to proceed',
    SUBSCRIPTION_ALREADY_EXISTS = 'Subscription already exists for one or more event types',
    EVENT_UPDATED_SUCCESSFULLY='Event has been successfully updated',
    CREDENTIALS_DONT_HAVE_ACCESS='Credential Provided do not have the required access to one or more subscriber',
    CREDENTIALS_NO_ACCESS_SUB='The credentials used does not have the access to the subscriber',
    DATA_UPDATED_SUCCESSFULLY='Subscriber has been successfully updated',
    CONTACTS_DEFAULT_INFO_MESSAGE = 'Atleast one Tech Ops and one Business Ops contact is required to complete subscriber setup',
    HIERARCHY_VALUE_GREATER_200 = 'Hierarchy value should not be greater than 200 characters',
    INVALID_TOKEN_URL = 'Please enter valid URL',
    DRAFT_SUBSCRIBER_TITLE = 'You have reached maximum number of draft records, Delete one of the record before adding new subscriber or continue editing existing record below',
    CONTACT_UPDATE_SUCCESSFULLY= 'Contact detail(s) updated in draft record',
    NO_RECORD_EXPORT='No records to export',
    NO_RECORD_VIEW='No records to view',
    FORBIDDEN = 'Forbidden, try again later or contact the system administrator',
    GONE = 'The target resource is no longer available, try again later or contact the system administrator',
    BAD_REQUEST = 'Bad request, try again later or contact the system administrator',
    UNAUTHORIZED = 'Unaauthorized error, try again later or contact the system administrator',
    NOT_FOUND_REQUEST = 'URL not found, try again later or contact the system administrator',
    HIERARCHY_VALUESTART_WITH_SPACE = 'Hierarchy Value should not start with blank spaces',
    CONTACT_NAME_WITH_SPACE = 'Contact Name should not start or end with blank spaces',
    GREATER_THAN_25CHAR = 'Should not be greater than 25 characters',
    GREATER_THAN_40CHAR = 'Should not be greater than 40 characters',
    MAIL_SENT_SUCCESSFULLY = 'Request has been Submitted',
    TIME_OUT_504 = 'Search Returned too many records - Kindly refine the search parameters',
    GREATER_THAN_160CHAR= 'Should not be greater than 160 characters',
    WEBHOOK_SAVED_SUCCESSFULLY = 'Webhook saved successfully',
    DISCARD_CHANGES_ORCONTINUE ='There are unsaved changes. Click \"Continue\" to save your changes or \"Exit\" to discard your changes',
    TECH_OPS_NAME_START_WITH_SPACE = 'Tech Ops Name should not start with blank spaces',
    WEBHOOK_URL_START_WITH_SPACE = 'Webhook url should not start with blank spaces',
    BUSINESS_OPS_NAME_START_WITH_SPACE = 'Business Ops Name should not start with blank spaces',
    CHANGE_PWD_CONFIRM = 'Changing the password will require opening a new browser tab and ending the current user session',
    ALLOWED_SPECIAL_CHARACTERS = 'Only following special characters allowed ',
    ALLOWED_SPECIAL_CHARACTER = 'Only following special character allowed ',
    ALLOWED_STRING_WITH_NO_SPACE = 'Only alphabet value is allowed with no spaces',
    ALLOWED_STRING_AND_NO_WITH_NO_SPACE = 'Only alphabet and numeric value is allowed with no spaces',
    ALLOWED_STRING_AND_NO = 'Only alphabet and numeric value is allowed',
    HIERARCHY_STATIC_ERROR = 'Hierarchy updation for one or more event(s) has failed. Please try after some time or contact the system administrator',
    VALID_PHONENO = 'Please enter valid phone number', 
    CONTACT_NAME = 'Only following special characters allowed \' - _ ', 
    SUBSCRIBER_CODE = 'Only following special characters allowed - ~ _',
    DASHBOARD_TIME_OUT_504 = 'Please try after some time or contact the system administrator'
}