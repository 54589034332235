import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService, ID_TOKEN } from '../auth.service';

/**
 * Intercept issueToken response to remove id_token from response in case of encrypted tokens.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpTokenInterceptor implements HttpInterceptor {
  refreshInProgress = false;

  constructor(private auth: AuthService, private storage: OAuthStorage) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.shouldHandle(request)).pipe(
      switchMap((handle) => {
        if (handle) {
          return next.handle(request).pipe(
            map((evt) => {
              if (evt instanceof HttpResponse) {
                if (evt.body && evt.body.id_token) {
                  // Token is encrypted.
                  // store id_token but remove it from response so that
                  // angular-oauth2-oidc does not process it.
                  // This fix is needed because angular-oauth2-oidc tries to decode encrypted token otherwise.
                  this.storage.setItem(ID_TOKEN, evt.body.id_token);
                  delete evt.body.id_token;
                }
              }
              return evt;
            })
          );
        }
        return next.handle(request);
      })
    );
  }

  private async shouldHandle(req: HttpRequest<any>): Promise<boolean> {
    // process only in case of encrypted tokens and issuetoken request
    const doc = this.auth.discoveryDocument;
    if (!doc || req.url !== doc.token_endpoint) {
      return false;
    }
    const config = await this.auth.config();
    return config.encrypted && doc && req.url === doc.token_endpoint;
  }
}
