import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { noop } from 'rxjs';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { IDropdownSettings, ListFilterPipe} from 'src/ng-multiselect-dropdown/src';
import { ListItem } from 'src/ng-multiselect-dropdown/src/multiselect.model';
import { ControlValueAccessor, UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { SharedService } from 'src/app/services';
@Component({
  selector: 'app-gen-multiselect-dropdown',
  templateUrl: './gen-multiselect-dropdown.component.html',
  styleUrls: ['./gen-multiselect-dropdown.component.scss'],
})
export class GenMultiselectDropdownComponent implements  ControlValueAccessor {

  @ViewChild('checkbox')
  public mulObj: MultiSelectComponent;
  // @ViewChild('selectall',{static: true})
  // public checkboxObj: CheckBoxComponent;
  public mode: string;   
  // set the MultiSelect popup height
  public popHeight: string = '280px';
  public numericValue: number = 3;
  // set the maximum selection length in Multiselect.
  public maxSelection: number = 3; 
  public _settings: IDropdownSettings;
  public _data: Array<ListItem> = [];
  public selectedItems: Array<ListItem> = [];
  public deselectedItems: Array<ListItem> = [];
  public isDropdownOpen = true;
  _placeholder = "Select";
  _filterPlaceholder="Select";
  private _sourceDataType = null; // to keep note of the source data type. could be array of string/number/object
  private _sourceDataFields: Array<String> = []; // store source data fields names
  filter: ListItem = new ListItem(this.data);
  public isSelect=true;

  public searchData: { [key: string]: Object }[]=[];
  
  // map the groupBy field with category column
  public checkFields: Object = { text: 'text', value: 'id' };
  public _versionChng:string;
 
   @Input()
   multiDropdown:any;

   genMultiSelectForm:UntypedFormGroup;

   @Output() isChild1FormValid: EventEmitter<any> = new EventEmitter<any>();
   @Output() close: EventEmitter<any> = new EventEmitter<any>();

  defaultSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "text",
    disabledField: "isDisabled",
    enableCheckAll: true,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: false,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 197,
    itemsShowLimit: 999999999999,
    searchPlaceholderText: "Search",
    noDataAvailablePlaceholderText: "No data available",
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false
  };
  searchResult: any;

  @Input()
  public set filterPlaceholder(value: string) {
    if (value) {
      this._filterPlaceholder = value;
    } else {
      this._filterPlaceholder = "Select";
    }
  }

  
  @Input()
  public set placeholder(value: string) {
    if (value) {
      this._placeholder = value;
    } else {
      this._placeholder = "Select";
    }
  }

  @Input()
  disabled = false;

  @Input()
  public set settings(value: IDropdownSettings) {
    if (value) {
      this._settings = Object.assign(this.defaultSettings, value);
    } else {
      this._settings = Object.assign(this.defaultSettings);
    }
  }

  @Input()
  public set isDataChange(value:any) { 
    this.initializeForm();
    this.genMultiSelectForm.get('multiDropdown').setValue(null);  

  }

  @Input()
  public set data(value: Array<any>) {    
  setTimeout(() => {
      this.genMultiSelectForm.get('multiDropdown').setValue(null);
      this.initializeForm();
    },);   
  
    if (!value) { 
      this._data = [];     
    } else {      
      const firstItem = value[0];
      this._sourceDataType = typeof firstItem;
      this._sourceDataFields = this.getFields(firstItem);
      this._data = value.map((item: any) =>
        typeof item === "string" || typeof item === "number"
          ? new ListItem(item)
          : new ListItem({
              id: item[this._settings.idField],
              text: item[this._settings.textField],
              isDisabled: item[this._settings.disabledField]
            })
      );   
      
    }
    if(this.selectedItems.length>=0){
     this.selectedItems=[]
    }
    if(value!=undefined && value.length==0){    
      this.isSelect=false;
    }else{
      this.isSelect=true;
    }   

  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //Added --end

  
  constructor(private listFilterPipe:ListFilterPipe,private fb: UntypedFormBuilder,private  sharedService:SharedService,) {
    
  }
  registerOnChange(fn: any): void {
    throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
    throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
      this.mode = 'CheckBox';     
      this.initializeForm();
      
  }

  getFields(inputData) {
      const fields = [];
      if (typeof inputData !== "object") {
        return fields;
      }
      // tslint:disable-next-line:forin
      for (const prop in inputData) {
        fields.push(prop);
      }
      return fields;
    }


    writeValue(value: any) {
      if (value !== undefined && value !== null && value.length > 0) {
        if (this._settings.singleSelection) {
          try {
            if (value.length >= 1) {
              const firstItem = value[0];
              this.selectedItems = [
                typeof firstItem === "string" || typeof firstItem === "number"
                  ? new ListItem(firstItem)
                  : new ListItem({
                      //id: firstItem[this._settings.idField],
                      text: firstItem[this._settings.textField],
                      isDisabled: firstItem[this._settings.disabledField]
                    })
              ];
            }
          } catch (e) {
            // console.error(e.body.msg);
          }
        } else {
          const _data = value.map((item: any) =>
            typeof item === "string" || typeof item === "number"
              ? new ListItem(item)
              : new ListItem({
                  id: item[this._settings.idField],
                  text: item[this._settings.textField],
                  isDisabled: item[this._settings.disabledField]
                })
          );
          if (this._settings.limitSelection > 0) {
            this.selectedItems = _data.splice(0, this._settings.limitSelection);
          } else {
            this.selectedItems = _data;
          }
        }
      } else {
        this.selectedItems = [];
      }
      this.onChangeCallback(value);
    }

    selectItem(event){       
      this.selectedItems.push(event.itemData);
      this.onChangeCallback(this.emittedValue(this.selectedItems));    
      this.genMultiSelectForm.get('multiDropDownValues').setValue(this.selectedItems);
      this.isChild1FormValid.emit(this.genMultiSelectForm);    
     }
     removed(event){      
      this.selectedItems.forEach(item => {
        if (event.itemData.id===item.id  ) {
          this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        }
      });
      this.onChangeCallback(this.emittedValue(this.selectedItems));    
      this.genMultiSelectForm.get('multiDropDownValues').setValue(this.selectedItems);
      this.isChild1FormValid.emit(this.genMultiSelectForm);
    
      
    }
     
     emittedValue(val: any): any {
       const selected = [];
       if (Array.isArray(val)) {
         val.map(item => {
           selected.push(this.objectify(item));
         });
       } else {
         if (val) {
           return this.objectify(val);
         }
       }
       return selected;
     }
 
     objectify(val: ListItem) {
       if (this._sourceDataType === 'object') {
         const obj = {};
         obj[this._settings.idField] = val.id;
         obj[this._settings.textField] = val.text;
         if (this._sourceDataFields.includes(this._settings.disabledField)) {
           obj[this._settings.disabledField] = val.isDisabled;
         }
         return obj;
       }
       if (this._sourceDataType === 'number') {
         return Number(val.id);
       } else {
         return val.text;
       }
     }    
    
     initializeForm(){       
      this.genMultiSelectForm = this.fb.group({
      multiDropdown: [''],
      multiDropDownValues:[ListItem]     
      });
      this.selectedItems=[]
    }
  
    closeHandler(event){      
      this.close.emit(this.genMultiSelectForm);
    }


}
