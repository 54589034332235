import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends ApiService {

}