export class GlobalConstants {
  static INVALID: string = 'INVALID';
  static VALID: string = 'VALID';
  static SAVE_AND_CONTINUE: string = 'Save and Continue';
  static SAVE_AND_EXIT: string = 'Save and Exit';
  static EXIT: string = 'Exit';
  static GLOSSARY_SOURCE = 'assets/Glossary.xlsx';
  static ADD: string = 'add';
  static EDIT: string = 'edit';
  static UPDATE_OPERATION = 'Update';
  static DELETE_OPERATION = 'Delete';
  static CHECK_OPERATION = 'Check';
  static TECH_OPS ='TECH_OPS';
  static BUSINESS_OPS='BUSINESS_OPS'
  static DRAFT: string = 'draft';
  static SUBSCRIPTION_ALREADY_EXISTS_MSG='Subscription already exists for one or more event types';
  static SUBSCRIPTION_ALREADY_EXISTS_CONTACT_MSG='Subscription already exists for one or more contacts';
  static RESPONSE_MESSAGE_SEGMENT ='responseMessageSegment'
  static EXPORT : string = 'export';
  static DELETE_CONFIRMATION ='Delete Confirmation!';
}