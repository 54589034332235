import { DOCUMENT } from "@angular/common";
import {
  Component,
  OnInit,
  Inject,
  ViewChild
} from "@angular/core";
import { SharedService } from "src/app/services/shared.service";
import * as XLSX from "xlsx";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CircularQueue } from "../model/CircularQueue";
import { QueriesComponent } from "../queries/queries.component";
import { NgxSpinnerService } from "ngx-spinner";
type AOA = any[][];

@Component({
  selector: "app-glossary",
  templateUrl: "./glossary.component.html",
  styleUrls: ["./glossary.component.scss"],
})
export class GlossaryComponent implements OnInit {
  data: AOA;
  hoverFlag: Boolean = false;
  clickedIcon: String = 'A';
  private window: Window;
  searchText = "";
  currentText = "";
  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl(""),
  });
  circularQueue: CircularQueue;
  counter: number = 0;
  counterTotal: number = 0;
  searchHighlightColor: string = "background-color : rgb(75 205 62 / 70%)";
  currentSearchHighlightColor: string = "background-color : yellow";
  activeTab: string = "userDocument";

  @ViewChild(QueriesComponent) queriesComponent:QueriesComponent;

  constructor(
    private service: SharedService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit() {
    this.spinner.show();
    this.activeTab = "userDocument";
    this.read(0);
    this.spinner.hide();
  }

  onChange(event) {
    if (event == 'userDocument') {
      this.activeTab = "userDocument";
    }
    if (event == 'glossary') {
      this.activeTab = "glossary";
    }
    if (event == 'contactUs') {
      this.activeTab = "contactUs";
    }
  }

  scrollHandler(event) {
    var doc = document.getElementById("items");
    for (var i = 1; i < doc.childNodes.length; i++) {
      if (this.isVisible(doc.childNodes[i])) {
        var idOnTop = doc.childNodes[i]["id"];
        this.clickedIcon = idOnTop;
        return idOnTop;
      }
    }
  }

  isVisible(elem: any) {
    if (!(elem instanceof HTMLElement))
      throw Error("DomUtil: elem is not an element.");
    const style = getComputedStyle(elem);
    if (style.display === "none") return false;
    if (style.visibility !== "visible") return false;
    // if (style.opacity < 0.1) return false;
    if (
      elem.offsetWidth +
        elem.offsetHeight +
        elem.getBoundingClientRect().height +
        elem.getBoundingClientRect().width ===
      0
    ) {
      return false;
    }
    const elemCenter = {
      x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
      y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
    };
    if (elemCenter.x < 0) return false;
    if (
      elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)
    )
      return false;
    if (elemCenter.y < 0) return false;
    if (
      elemCenter.y >
      (document.documentElement.clientHeight || window.innerHeight)
    )
      return false;
    let pointContainer : Element = document.elementFromPoint(elemCenter.x, elemCenter.y);
    do {
      if (pointContainer === elem){
        return true;
      } 
    } while ((pointContainer = pointContainer.parentElement ));
    return false;
  }

  read(sheetIndex: number) {
    this.service.readGLossarySource().subscribe((data: any) => {
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      };
      reader.readAsBinaryString(data);
    });
  }

  downloadFile() {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", "assets/BenPortalUserGuide.pdf");
    link.setAttribute("download", `BenPortalUserGuide.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onHoverChangeStyle() {
    this.hoverFlag = true;
  }

  onOutChangeStyle() {
    this.hoverFlag = false;
  }

  iconCLicked(alphabet: string, sheetIndex: number) {
    if (this.ifDataPresent(alphabet)) {
      this.clickedIcon = alphabet;
      this.scroll(alphabet);
    }
    //this.read(sheetIndex);
  }

  scroll(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth" });
  }

  goToNext() {
    const searchedElements = document.getElementsByClassName(
      "highlightSearchText"
    );
    if (searchedElements.length == 0) {
      this.counter = 0;
      this.counterTotal = 0;
      this.currentText = 'A';
    }

    if (searchedElements.length != 0) {
      this.counterTotal = searchedElements.length;
      //first search and enter press
      if (this.currentText != this.searchText) {
        this.counter = 0;
        this.currentText = this.searchText;
        this.circularQueue = new CircularQueue(searchedElements.length);
        for (let i = 0; i < searchedElements.length; i++) {
          this.circularQueue.enqueue(searchedElements[i]);
        }
        this.scrollAndHighlight(this.circularQueue.getFront());
        this.counter = this.counter + 1;
      }

      //cycle reset loop
      else if (
        this.currentText == this.searchText &&
        this.counter == searchedElements.length
      ) {
        this.circularQueue.element[searchedElements.length - 1].style =
        this.searchHighlightColor;
        this.counter = 0;
        this.scrollAndHighlight(this.circularQueue.element[0]);
        this.counter = this.counter + 1;
      }

      //next enter presses loop in cycle
      else if (
        this.currentText == this.searchText &&
        this.counter < searchedElements.length &&
        this.counter != 0
      ) {
        this.scrollAndHighlight(this.circularQueue.element[this.counter]);
        this.circularQueue.element[this.counter - 1].style =
          this.searchHighlightColor;
        this.counter = this.counter + 1;
      }
    }
  }

  goToPrev() {
    const searchedElements = document.getElementsByClassName(
      "highlightSearchText"
    );

    //when at 0 element
    if (this.currentText == this.searchText && this.counter == 1) {
      this.counter = searchedElements.length;
      this.scrollAndHighlight(searchedElements[searchedElements.length - 1]);
      this.circularQueue.element[0].style = this.searchHighlightColor;
    }

    //cycle back loop
    else if (this.currentText == this.searchText && this.counter > 1) {
      this.counter = this.counter - 1;
      this.scrollAndHighlight(searchedElements[this.counter - 1]);
      this.circularQueue.element[this.counter].style =
        this.searchHighlightColor;
    }
  }

  scrollAndHighlight(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
    element.style = this.currentSearchHighlightColor;
  }

  ifDataPresent(letter: string): boolean {
    var doc = document.getElementById("items");
    for (var i = 1; i < doc.childNodes.length; i++) {
      var idOnTop = doc.childNodes[i]["id"];
      if (letter == idOnTop) {
        return true;
      }
    }
    return false;
  }

  clearText() {
     this.counter = 0;
     this.counterTotal = 0;
    if(this.searchText! = ''){
     this.searchForm.get('search').setValue('');
     this.clickedIcon = 'A';
     const searchedElements = document.getElementsByClassName("highlightSearchText");
     this.scrollAndHighlight(searchedElements[1]);
    }
  }

  ionViewDidLeave() {
    this.activeTab = "userDocument";
    document.getElementById("userIDDocument").click();
    this.queriesComponent.ngOnInit();
  }
  
}
