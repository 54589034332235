import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { AuthWindowRef } from './util';

export interface AuthGuardData {
  require?: 'any' | 'all';
  authorizations: string[];
}

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    @Inject(AuthWindowRef)
    private win
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const data = route.data as AuthGuardData;
    const search = this.win.location.search;
    const code = !!(search && search.indexOf('code=') !== -1);

    // set initial path for redirecting after login
    if (state && state.url) {
      let url = decodeURIComponent(state.url);

      // if url contains code, state.url will be the default route.
      // in that case, do not overwrite initialPath
      if (!code) {
        // url = url + search;
        this.authService.initialPath = url;
      } else if (!this.authService.initialPath) {
        url = decodeURIComponent(state.url).split(/[?#]/)[0];
        this.authService.initialPath = url;
      }
    }

    return this.authService.login(data);
  }
}
