import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCaseWithExceptions'
})

export class TitleCaseWithExceptions implements PipeTransform {
    //transform(value: string, exceptions: string[] = []): string {
        transform(value: any, exceptions: any[] = [], ...args: any[]): any {
       if(!value) return value;
       
       const words = value.split(' ');

       const resultWords = words.map(word => {
        const lowerCaseWord = word.toLowerCase();
        const upperCaseWord = word.toUpperCase();
        return exceptions.includes(upperCaseWord) ? word : this.capitalizeFirstLetter(lowerCaseWord);
       });

       return resultWords.join(' ');
    }

    private capitalizeFirstLetter(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
}
 