import { Injectable } from '@angular/core';
import { TokenStore, AuthService } from '@ruf/idp-auth';
import { ENV } from '@app/env';
import { ENDPOINTS } from '@app/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SessionManagementServiceProvider {

  isLoggedIn: boolean;
  displaySessionTimeoutWarning = false;

  constructor(private authService: AuthService, private tokenStore: TokenStore) {
    if (ENV.USING_IDP) {
      if(this.authService.isLoggedIn) {
        this.isLoggedIn = true;
      }
      else
      {
        this.isLoggedIn = false;
      }
    }
  }

  checkTokenExpiry(claims: any): number {
    //this.userInformation = claims;
    return 0;//this.userInformation.EXP - this.userInformation.IAT;
  }

  logout(): void {
    // TokenStore.removeAll() does not actually remove all of the below,
    // no matter how much you might think it really should
    let logouturl = localStorage.getItem('logoutUrl');
    let firmName = localStorage.getItem('firmName');
    this.isLoggedIn = false;
    localStorage.clear();
    sessionStorage.clear();

    setTimeout(() => {
      localStorage.setItem('isUserLoggedOut', 'true');
      localStorage.setItem('logOutFirm', firmName);
      this.authService.logout(true).then((value) => {
        setTimeout(() => {
          window.location.href = logouturl;
        })
      }, err => {});
    },10)    
    
  }

}
