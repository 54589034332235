import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { IonicModule } from '@ionic/angular';

import { PaginationComponent } from './pagination/pagination.component';
import { PreloaderComponent } from './preloader/preloader.component';

import {SearchFilterPipe} from './pipes/search.pipe';

import { CheckBoxDropDown } from './checkbox-dropdown/checkbox-dropdown.component';
import { PopupMenu } from './popupmenu/popupmenu.component'
import { AlertComponent } from './alerts/alert.component';
import { GenMultiselectDropdownComponent } from 'src/app/shared/gen-multiselect-dropdown/gen-multiselect-dropdown.component';
 
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { CheckBoxSelectionService, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { ReplaceUnderscorePipe } from './pipes/replace-underscore.pipe';
import { TitleCaseWithExceptions } from './pipes/title-case-with-exceptions.pipe';
import { RemoveUnderscores } from './pipes/remove-underscores.pipe';
import { HighlightDirective } from './highlight-directive/highlight.directive';
import {SearchGlossaryFilterPipe } from './pipes/search-filter.pipe';
import { MatDialog, MatDialogModule, MatDialogRef,MatDialogActions,MatDialogContent } from '@angular/material/dialog';
import {DialogAlertContentComponent} from 'src/app/shared/errorAlert/dialogAlertContent.component';
import { PopupMerchantHierarchy } from 'src/app/shared/popup-merchant-hierarchy/popup-merchant-hierarchy.component';
import { PopupViewMerchantHierarchy } from 'src/app/shared/popup-view-merchant-hierarchy/popup-view-merchant-hierarchy.component';
import { ExportReportComponent } from './export-report/export-report.component';
import { MatSelectModule } from '@angular/material/select';
import { QueriesComponent } from './queries/queries.component';
import { TimeESTEDTPipe } from './pipes/time-est-edt.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationDialog } from './popup-confirmation/confirmation-dialog';
import { PopupViewAuthorizedHierarchy } from './popup-view-authorized-hierarchy/popup-view-authorized-hierarchy.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        ButtonModule,
        CheckBoxModule,
        MultiSelectAllModule,
        NgxSpinnerModule,
        MatDialogModule,
        MatSelectModule
    ],
    declarations: [
        PaginationComponent,
        PreloaderComponent,
        SearchFilterPipe,
        TimeESTEDTPipe,
        CheckBoxDropDown,
        PopupMenu,
        AlertComponent,
        GenMultiselectDropdownComponent,
        CustomPaginationComponent,
        ReplaceUnderscorePipe,
        TitleCaseWithExceptions,
        RemoveUnderscores,
        HighlightDirective,
        SearchGlossaryFilterPipe,
        DialogAlertContentComponent,
        PopupMerchantHierarchy,
        PopupViewMerchantHierarchy,
        PopupViewAuthorizedHierarchy,
        ExportReportComponent,
        QueriesComponent,
        ConfirmationDialog
    ],
    exports: [
        PaginationComponent,
        PreloaderComponent,
        SearchFilterPipe,
        TimeESTEDTPipe,
        CheckBoxDropDown,
        PopupMenu,
        AlertComponent,
        GenMultiselectDropdownComponent,
        CustomPaginationComponent,
        ReplaceUnderscorePipe,
        TitleCaseWithExceptions,
        RemoveUnderscores,
        HighlightDirective,
        SearchGlossaryFilterPipe,
        QueriesComponent,
        ConfirmationDialog
    ],
    providers: [CheckBoxSelectionService, ReplaceUnderscorePipe, MatDialog, MatDialogModule,]
})
export class SharedModule { }
